import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionChanges,
  doc,
  docSnapshots,
  DocumentReference,
  Firestore,
  orderBy,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import {
  Invoice,
  Printer,
  Provider,
  ProviderDriverPreferences,
  ProviderMenuPreferences,
  ProviderOrderPreferences,
  ProviderSetting,
} from '@bringmos/types';
import { CollectionReference, query } from 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  public readonly providerSettings$: Observable<ProviderSetting | null> = this.authService.provider.pipe(
    switchMap((provider) => {
      if (provider && provider.uid) {
        return this.getProviderSettings(provider.uid);
      } else {
        return of(null);
      }
    }),
  );
  constructor(private authService: AuthService, public firestore: Firestore) {}

  public updateProvider(id: string, provider: Partial<Provider>): Promise<void> {
    const col = collection(this.firestore, 'Providers');
    const document = doc(col, id);
    return updateDoc(document, provider);
  }

  public getProviderSettings(providerId: string): Observable<ProviderSetting> {
    const col = collection(this.firestore, 'provider-settings');
    const document = doc(col, providerId);
    return docSnapshots(document).pipe(
      map((doc) => {
        return doc.data() as ProviderSetting;
      }),
    );
  }

  public updateProviderSettings(id: string, settings: Partial<ProviderSetting>): Promise<void> {
    const col = collection(this.firestore, 'provider-settings');
    const document = doc(col, id);
    return updateDoc(document, settings);
  }

  public getProviderMenuPreferences(id: string): Observable<ProviderMenuPreferences> {
    const col = collection(this.firestore, 'Providers');
    const document = doc(col, id);
    const prefCol = collection(document, 'Preferences');
    const prefDoc = doc(prefCol, 'menu');

    return docSnapshots(prefDoc).pipe(map((doc) => doc.data() as ProviderMenuPreferences));
  }

  public updateProviderMenuPreferences(id: string, values: any): Promise<void> {
    const col = collection(this.firestore, 'Providers');
    const document = doc(col, id);
    const pref = collection(document, 'Preferences');
    const menuDoc = doc(pref, 'menu');
    return updateDoc(menuDoc, values, { merge: true });
  }

  public updateProviderDriverPreferences(id: string, values: any): Promise<void> {
    const col = collection(this.firestore, 'Providers');
    const document = doc(col, id);
    const pref = collection(document, 'Preferences');
    const menuDoc = doc(pref, 'driver');
    return updateDoc(menuDoc, values, { merge: true });
  }

  public getProviderDriverPreferences(id: string): Observable<ProviderDriverPreferences> {
    const col = collection(this.firestore, 'Providers');
    const document = doc(col, id);
    const prefCol = collection(document, 'Preferences');
    const prefDoc = doc(prefCol, 'driver');
    return docSnapshots(prefDoc).pipe(map((doc) => doc.data() as ProviderDriverPreferences));
  }

  public updateProviderOrderPreferences(id: string, values: any): Promise<void> {
    const col = collection(this.firestore, 'Providers');
    const document = doc(col, id);
    const pref = collection(document, 'Preferences');
    const menuDoc = doc(pref, 'orders');
    return updateDoc(menuDoc, values, { merge: true });
  }

  public getProviderOrderPreferences(id: string): Observable<ProviderOrderPreferences> {
    const col = collection(this.firestore, 'Providers');
    const document = doc(col, id);
    const prefCol = collection(document, 'Preferences');
    const prefDoc = doc(prefCol, 'orders');
    return docSnapshots(prefDoc).pipe(map((doc) => doc.data() as ProviderOrderPreferences));
  }

  public addInvoice(invoice: Invoice): Promise<DocumentReference<unknown>> {
    const col = collection(this.firestore, 'invoices');
    return addDoc(col, invoice);
  }

  public getInvoices(providerId: string): Observable<Invoice[]> {
    const invoiceCollection = collection(this.firestore, 'invoices') as CollectionReference<Invoice>;
    const ref = query<Invoice>(invoiceCollection, where('providerId', '==', providerId), orderBy('dateCreated', 'desc'));
    return collectionChanges(ref).pipe(map((changes) => changes.map((c) => c.doc.data())));
  }

  public getPrinters(providerId: string): Observable<Printer> {
    const invoiceCollection = collection(this.firestore, 'Printers') as CollectionReference<Printer>;
    const printersDoc = doc(invoiceCollection, providerId);
    return docSnapshots(printersDoc).pipe(map((doc) => doc.data() as Printer));
  }

  public updatePrinters(providerId: string, printerData: any) {
    const col = collection(this.firestore, 'Printers');
    const document = doc(col, providerId);
    return updateDoc(document, printerData, { merge: true });
  }
}
