<div class="bringmos-form-field-wrapper" (click)="_control.onContainerClick($event)">
  <ng-content select="bringmos-label"></ng-content>
  <div class="bringmos-rel" #inputContainer>
    <ng-content></ng-content>
    <ng-content select="bringmosSuffix"></ng-content>
  </div>
  <div class="bringmos-form-field-subscript-wrapper" [ngSwitch]="_getDisplayedMessages()">
    <div *ngSwitchCase="'error'" class="bringmos-form-field-error-wrapper" [@transitionMessages]="_subscriptAnimationState">
      <ng-content select="bringmos-error"></ng-content>
    </div>
    <div *ngSwitchCase="'hint'" class="bringmos-form-field-hint-wrapper" [@transitionMessages]="_subscriptAnimationState">
      <ng-content select="bringmos-hint"></ng-content>
    </div>
  </div>
</div>
