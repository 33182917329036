import { Pipe, PipeTransform } from '@angular/core';
import { Category, SupportedCategories } from '@bringmos/types';

@Pipe({
  name: 'categoryAvatar',
})
export class CategoryAvatarPipe implements PipeTransform {
  categoryDe: Category[] = SupportedCategories.SUPPORTED_CATEGORIES_DE;
  categoryIt: Category[] = SupportedCategories.SUPPORTED_CATEGORIES_IT;
  categoryEn: Category[] = SupportedCategories.SUPPORTED_CATEGORIES_EN;

  transform(code: string): string {
    if (code) {
      const ret: Category | undefined = this.categoryDe.find((element) => element.code === code);

      if (ret) {
        return ret.avatar;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
