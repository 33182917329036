export interface Country {
    isoCode: string,
    phoneCode: string,
    name: string,
    iso3Code: string,
};

export const COUNTRIES: Country[] = [
    {
        isoCode: "IT",
        phoneCode: "39",
        name: "Italy",
        iso3Code: "ITA",
    },
    {
        isoCode: "AT",
        phoneCode: "43",
        name: "Austria",
        iso3Code: "AUT",
    },
    {
        isoCode: "DE",
        phoneCode: "49",
        name: "Germany",
        iso3Code: "DEU",
    },
    {
        isoCode: "FR",
        phoneCode: "33",
        name: "France",
        iso3Code: "FRA",
    },
    {
        isoCode: "AF",
        phoneCode: "93",
        name: "Afghanistan",
        iso3Code: "AFG",
    },
    {
        isoCode: "AL",
        phoneCode: "355",
        name: "Albania",
        iso3Code: "ALB",
    },
    {
        isoCode: "DZ",
        phoneCode: "213",
        name: "Algeria",
        iso3Code: "DZA",
    },
    {
        isoCode: "AS",
        phoneCode: "1-684",
        name: "American Samoa",
        iso3Code: "ASM",
    },
    {
        isoCode: "AD",
        phoneCode: "376",
        name: "Andorra",
        iso3Code: "AND",
    },
    {
        isoCode: "AO",
        phoneCode: "244",
        name: "Angola",
        iso3Code: "AGO",
    },
    {
        isoCode: "AI",
        phoneCode: "1-264",
        name: "Anguilla",
        iso3Code: "AIA",
    },
    {
        isoCode: "AQ",
        phoneCode: "672",
        name: "Antarctica",
        iso3Code: "ATA",
    },
    {
        isoCode: "AG",
        phoneCode: "1-268",
        name: "Antigua and Barbuda",
        iso3Code: "ATG",
    },
    {
        isoCode: "AR",
        phoneCode: "54",
        name: "Argentina",
        iso3Code: "ARG",
    },
    {
        isoCode: "AM",
        phoneCode: "374",
        name: "Armenia",
        iso3Code: "ARM",
    },
    {
        isoCode: "AW",
        phoneCode: "297",
        name: "Aruba",
        iso3Code: "ABW",
    },
    {
        isoCode: "AU",
        phoneCode: "61",
        name: "Australia",
        iso3Code: "AUS",
    },
    {
        isoCode: "AZ",
        phoneCode: "994",
        name: "Azerbaijan",
        iso3Code: "AZE",
    },
    {
        isoCode: "BS",
        phoneCode: "1-242",
        name: "Bahamas",
        iso3Code: "BHS",
    },
    {
        isoCode: "BH",
        phoneCode: "973",
        name: "Bahrain",
        iso3Code: "BHR",
    },
    {
        isoCode: "BD",
        phoneCode: "880",
        name: "Bangladesh",
        iso3Code: "BGD",
    },
    {
        isoCode: "BB",
        phoneCode: "1-246",
        name: "Barbados",
        iso3Code: "BRB",
    },
    {
        isoCode: "BY",
        phoneCode: "375",
        name: "Belarus",
        iso3Code: "BLR",
    },
    {
        isoCode: "BE",
        phoneCode: "32",
        name: "Belgium",
        iso3Code: "BEL",
    },
    {
        isoCode: "BZ",
        phoneCode: "501",
        name: "Belize",
        iso3Code: "BLZ",
    },
    {
        isoCode: "BJ",
        phoneCode: "229",
        name: "Benin",
        iso3Code: "BEN",
    },
    {
        isoCode: "BM",
        phoneCode: "1-441",
        name: "Bermuda",
        iso3Code: "BMU",
    },
    {
        isoCode: "BT",
        phoneCode: "975",
        name: "Bhutan",
        iso3Code: "BTN",
    },
    {
        isoCode: "BO",
        phoneCode: "591",
        name: "Bolivia, Plurinational State of",
        iso3Code: "BOL",
    },
    {
        isoCode: "BA",
        phoneCode: "387",
        name: "Bosnia and Herzegovina",
        iso3Code: "BIH",
    },
    {
        isoCode: "BW",
        phoneCode: "267",
        name: "Botswana",
        iso3Code: "BWA",
    },
    {
        isoCode: "BV",
        phoneCode: "47",
        name: "Bouvet Island",
        iso3Code: "BVT",
    },
    {
        isoCode: "BR",
        phoneCode: "55",
        name: "Brazil",
        iso3Code: "BRA",
    },
    {
        isoCode: "IO",
        phoneCode: "246",
        name: "British Indian Ocean Territory",
        iso3Code: "IOT",
    },
    {
        isoCode: "BN",
        phoneCode: "673",
        name: "Brunei Darussalam",
        iso3Code: "BRN",
    },
    {
        isoCode: "BG",
        phoneCode: "359",
        name: "Bulgaria",
        iso3Code: "BGR",
    },
    {
        isoCode: "BF",
        phoneCode: "226",
        name: "Burkina Faso",
        iso3Code: "BFA",
    },
    {
        isoCode: "BI",
        phoneCode: "257",
        name: "Burundi",
        iso3Code: "BDI",
    },
    {
        isoCode: "KH",
        phoneCode: "855",
        name: "Cambodia",
        iso3Code: "KHM",
    },
    {
        isoCode: "CM",
        phoneCode: "237",
        name: "Cameroon",
        iso3Code: "CMR",
    },
    {
        isoCode: "CA",
        phoneCode: "1",
        name: "Canada",
        iso3Code: "CAN",
    },
    {
        isoCode: "CV",
        phoneCode: "238",
        name: "Cape Verde",
        iso3Code: "CPV",
    },
    {
        isoCode: "BQ",
        phoneCode: "599",
        name: "Caribbean Netherlands",
        iso3Code: "BES",
    },
    {
        isoCode: "KY",
        phoneCode: "1-345",
        name: "Cayman Islands",
        iso3Code: "CYM",
    },
    {
        isoCode: "CF",
        phoneCode: "236",
        name: "Central African Republic",
        iso3Code: "CAF",
    },
    {
        isoCode: "TD",
        phoneCode: "235",
        name: "Chad",
        iso3Code: "TCD",
    },
    {
        isoCode: "CL",
        phoneCode: "56",
        name: "Chile",
        iso3Code: "CHL",
    },
    {
        isoCode: "CN",
        phoneCode: "86",
        name: "China",
        iso3Code: "CHN",
    },
    {
        isoCode: "CX",
        phoneCode: "61",
        name: "Christmas Island",
        iso3Code: "CXR",
    },
    {
        isoCode: "CC",
        phoneCode: "61",
        name: "Cocos (Keeling} Islands",
        iso3Code: "CCK",
    },
    {
        isoCode: "CO",
        phoneCode: "57",
        name: "Colombia",
        iso3Code: "COL",
    },
    {
        isoCode: "KM",
        phoneCode: "269",
        name: "Comoros",
        iso3Code: "COM",
    },
    {
        isoCode: "CG",
        phoneCode: "242",
        name: "Congo",
        iso3Code: "COG",
    },
    {
        isoCode: "CD",
        phoneCode: "243",
        name: "Congo, the Democratic Republic of the",
        iso3Code: "COD",
    },
    {
        isoCode: "CK",
        phoneCode: "682",
        name: "Cook Islands",
        iso3Code: "COK",
    },
    {
        isoCode: "CR",
        phoneCode: "506",
        name: "Costa Rica",
        iso3Code: "CRI",
    },
    {
        isoCode: "HR",
        phoneCode: "385",
        name: "Croatia",
        iso3Code: "HRV",
    },
    {
        isoCode: "CU",
        phoneCode: "53",
        name: "Cuba",
        iso3Code: "CUB",
    },
    {
        isoCode: "CW",
        phoneCode: "599",
        name: "Curaçao",
        iso3Code: "CUW",
    },
    {
        isoCode: "CY",
        phoneCode: "357",
        name: "Cyprus",
        iso3Code: "CYP",
    },
    {
        isoCode: "CZ",
        phoneCode: "420",
        name: "Czech Republic",
        iso3Code: "CZE",
    },
    {
        isoCode: "CI",
        phoneCode: "225",
        name: "Côte d'Ivoire",
        iso3Code: "CIV",
    },
    {
        isoCode: "DK",
        phoneCode: "45",
        name: "Denmark",
        iso3Code: "DNK",
    },
    {
        isoCode: "DJ",
        phoneCode: "253",
        name: "Djibouti",
        iso3Code: "DJI",
    },
    {
        isoCode: "DM",
        phoneCode: "1-767",
        name: "Dominica",
        iso3Code: "DMA",
    },
    {
        isoCode: "DO",
        phoneCode: "1-849",
        name: "Dominican Republic",
        iso3Code: "DOM",
    },
    {
        isoCode: "EC",
        phoneCode: "593",
        name: "Ecuador",
        iso3Code: "ECU",
    },
    {
        isoCode: "EG",
        phoneCode: "20",
        name: "Egypt",
        iso3Code: "EGY",
    },
    {
        isoCode: "SV",
        phoneCode: "503",
        name: "El Salvador",
        iso3Code: "SLV",
    },
    {
        isoCode: "GB-ENG",
        phoneCode: "44",
        name: "England",
        iso3Code: "GBR",
    },
    {
        isoCode: "GQ",
        phoneCode: "240",
        name: "Equatorial Guinea",
        iso3Code: "GNQ",
    },
    {
        isoCode: "ER",
        phoneCode: "291",
        name: "Eritrea",
        iso3Code: "ERI",
    },
    {
        isoCode: "EE",
        phoneCode: "372",
        name: "Estonia",
        iso3Code: "EST",
    },
    {
        isoCode: "ET",
        phoneCode: "251",
        name: "Ethiopia",
        iso3Code: "ETH",
    },
    {
        isoCode: "FK",
        phoneCode: "500",
        name: "Falkland Islands (Malvinas}",
        iso3Code: "FLK",
    },
    {
        isoCode: "FO",
        phoneCode: "298",
        name: "Faroe Islands",
        iso3Code: "FRO",
    },
    {
        isoCode: "FJ",
        phoneCode: "679",
        name: "Fiji",
        iso3Code: "FJI",
    },
    {
        isoCode: "FI",
        phoneCode: "358",
        name: "Finland",
        iso3Code: "FIN",
    },
    {
        isoCode: "GF",
        phoneCode: "594",
        name: "French Guiana",
        iso3Code: "GUF",
    },
    {
        isoCode: "PF",
        phoneCode: "689",
        name: "French Polynesia",
        iso3Code: "PYF",
    },
    {
        isoCode: "TF",
        phoneCode: "262",
        name: "French Southern Territories",
        iso3Code: "ATF",
    },
    {
        isoCode: "GA",
        phoneCode: "241",
        name: "Gabon",
        iso3Code: "GAB",
    },
    {
        isoCode: "GM",
        phoneCode: "220",
        name: "Gambia",
        iso3Code: "GMB",
    },
    {
        isoCode: "GE",
        phoneCode: "995",
        name: "Georgia",
        iso3Code: "GEO",
    },
    {
        isoCode: "GH",
        phoneCode: "233",
        name: "Ghana",
        iso3Code: "GHA",
    },
    {
        isoCode: "GI",
        phoneCode: "350",
        name: "Gibraltar",
        iso3Code: "GIB",
    },
    {
        isoCode: "GR",
        phoneCode: "30",
        name: "Greece",
        iso3Code: "GRC",
    },
    {
        isoCode: "GL",
        phoneCode: "299",
        name: "Greenland",
        iso3Code: "GRL",
    },
    {
        isoCode: "GD",
        phoneCode: "1-473",
        name: "Grenada",
        iso3Code: "GRD",
    },
    {
        isoCode: "GP",
        phoneCode: "590",
        name: "Guadeloupe",
        iso3Code: "GLP",
    },
    {
        isoCode: "GU",
        phoneCode: "1-671",
        name: "Guam",
        iso3Code: "GUM",
    },
    {
        isoCode: "GT",
        phoneCode: "502",
        name: "Guatemala",
        iso3Code: "GTM",
    },
    {
        isoCode: "GG",
        phoneCode: "44-1481",
        name: "Guernsey",
        iso3Code: "GGY",
    },
    {
        isoCode: "GN",
        phoneCode: "224",
        name: "Guinea",
        iso3Code: "GIN",
    },
    {
        isoCode: "GW",
        phoneCode: "245",
        name: "Guinea-Bissau",
        iso3Code: "GNB",
    },
    {
        isoCode: "GY",
        phoneCode: "592",
        name: "Guyana",
        iso3Code: "GUY",
    },
    {
        isoCode: "HT",
        phoneCode: "509",
        name: "Haiti",
        iso3Code: "HTI",
    },
    {
        isoCode: "HM",
        phoneCode: "672",
        name: "Heard Island and McDonald Islands",
        iso3Code: "HMD",
    },
    {
        isoCode: "VA",
        phoneCode: "379",
        name: "Holy See (Vatican City State}",
        iso3Code: "VAT",
    },
    {
        isoCode: "HN",
        phoneCode: "504",
        name: "Honduras",
        iso3Code: "HND",
    },
    {
        isoCode: "HK",
        phoneCode: "852",
        name: "Hong Kong",
        iso3Code: "HKG",
    },
    {
        isoCode: "HU",
        phoneCode: "36",
        name: "Hungary",
        iso3Code: "HUN",
    },
    {
        isoCode: "IS",
        phoneCode: "354",
        name: "Iceland",
        iso3Code: "ISL",
    },
    {
        isoCode: "IN",
        phoneCode: "91",
        name: "India",
        iso3Code: "IND",
    },
    {
        isoCode: "ID",
        phoneCode: "62",
        name: "Indonesia",
        iso3Code: "IDN",
    },
    {
        isoCode: "IR",
        phoneCode: "98",
        name: "Iran, Islamic Republic of",
        iso3Code: "IRN",
    },
    {
        isoCode: "IQ",
        phoneCode: "964",
        name: "Iraq",
        iso3Code: "IRQ",
    },
    {
        isoCode: "IE",
        phoneCode: "353",
        name: "Ireland",
        iso3Code: "IRL",
    },
    {
        isoCode: "IM",
        phoneCode: "44-1624",
        name: "Isle of Man",
        iso3Code: "IMN",
    },
    {
        isoCode: "IL",
        phoneCode: "972",
        name: "Israel",
        iso3Code: "ISR",
    },
    {
        isoCode: "JM",
        phoneCode: "1-876",
        name: "Jamaica",
        iso3Code: "JAM",
    },
    {
        isoCode: "JP",
        phoneCode: "81",
        name: "Japan",
        iso3Code: "JPN",
    },
    {
        isoCode: "JE",
        phoneCode: "44-1534",
        name: "Jersey",
        iso3Code: "JEY",
    },
    {
        isoCode: "JO",
        phoneCode: "962",
        name: "Jordan",
        iso3Code: "JOR",
    },
    {
        isoCode: "KZ",
        phoneCode: "7",
        name: "Kazakhstan",
        iso3Code: "KAZ",
    },
    {
        isoCode: "KE",
        phoneCode: "254",
        name: "Kenya",
        iso3Code: "KEN",
    },
    {
        isoCode: "KI",
        phoneCode: "686",
        name: "Kiribati",
        iso3Code: "KIR",
    },
    {
        isoCode: "KP",
        phoneCode: "850",
        name: "Korea, Democratic People's Republic of",
        iso3Code: "PRK",
    },
    {
        isoCode: "KR",
        phoneCode: "82",
        name: "Korea, Republic of",
        iso3Code: "KOR",
    },
    {
        isoCode: "XK",
        phoneCode: "383",
        name: "Kosovo",
        iso3Code: "KOS",
    },
    {
        isoCode: "KW",
        phoneCode: "965",
        name: "Kuwait",
        iso3Code: "KWT",
    },
    {
        isoCode: "KG",
        phoneCode: "996",
        name: "Kyrgyzstan",
        iso3Code: "KGZ",
    },
    {
        isoCode: "LA",
        phoneCode: "",
        name: "Lao People's Democratic Republic",
        iso3Code: "LAO",
    },
    {
        isoCode: "LV",
        phoneCode: "371",
        name: "Latvia",
        iso3Code: "LVA",
    },
    {
        isoCode: "LB",
        phoneCode: "961",
        name: "Lebanon",
        iso3Code: "LBN",
    },
    {
        isoCode: "LS",
        phoneCode: "266",
        name: "Lesotho",
        iso3Code: "LSO",
    },
    {
        isoCode: "LR",
        phoneCode: "231",
        name: "Liberia",
        iso3Code: "LBR",
    },
    {
        isoCode: "LY",
        phoneCode: "218",
        name: "Libya",
        iso3Code: "LBY",
    },
    {
        isoCode: "LI",
        phoneCode: "423",
        name: "Liechtenstein",
        iso3Code: "LIE",
    },
    {
        isoCode: "LT",
        phoneCode: "370",
        name: "Lithuania",
        iso3Code: "LTU",
    },
    {
        isoCode: "LU",
        phoneCode: "352",
        name: "Luxembourg",
        iso3Code: "LUX",
    },
    {
        isoCode: "MO",
        phoneCode: "853",
        name: "Macao",
        iso3Code: "MAC",
    },
    {
        isoCode: "MK",
        phoneCode: "389",
        name: "Macedonia, the former Yugoslav Republic of",
        iso3Code: "MKD",
    },
    {
        isoCode: "MG",
        phoneCode: "261",
        name: "Madagascar",
        iso3Code: "MDG",
    },
    {
        isoCode: "MW",
        phoneCode: "265",
        name: "Malawi",
        iso3Code: "MWI",
    },
    {
        isoCode: "MY",
        phoneCode: "60",
        name: "Malaysia",
        iso3Code: "MYS",
    },
    {
        isoCode: "MV",
        phoneCode: "960",
        name: "Maldives",
        iso3Code: "MDV",
    },
    {
        isoCode: "ML",
        phoneCode: "223",
        name: "Mali",
        iso3Code: "MLI",
    },
    {
        isoCode: "MT",
        phoneCode: "356",
        name: "Malta",
        iso3Code: "MLT",
    },
    {
        isoCode: "MH",
        phoneCode: "692",
        name: "Marshall Islands",
        iso3Code: "MHL",
    },
    {
        isoCode: "MQ",
        phoneCode: "596",
        name: "Martinique",
        iso3Code: "MTQ",
    },
    {
        isoCode: "MR",
        phoneCode: "222",
        name: "Mauritania",
        iso3Code: "MRT",
    },
    {
        isoCode: "MU",
        phoneCode: "230",
        name: "Mauritius",
        iso3Code: "MUS",
    },
    {
        isoCode: "YT",
        phoneCode: "262",
        name: "Mayotte",
        iso3Code: "MYT",
    },
    {
        isoCode: "MX",
        phoneCode: "52",
        name: "Mexico",
        iso3Code: "MEX",
    },
    {
        isoCode: "FM",
        phoneCode: "691",
        name: "Micronesia, Federated States of",
        iso3Code: "FSM",
    },
    {
        isoCode: "MD",
        phoneCode: "373",
        name: "Moldova, Republic of",
        iso3Code: "MDA",
    },
    {
        isoCode: "MC",
        phoneCode: "377",
        name: "Monaco",
        iso3Code: "MCO",
    },
    {
        isoCode: "MN",
        phoneCode: "976",
        name: "Mongolia",
        iso3Code: "MNG",
    },
    {
        isoCode: "ME",
        phoneCode: "382",
        name: "Montenegro",
        iso3Code: "MNE",
    },
    {
        isoCode: "MS",
        phoneCode: "1-664",
        name: "Montserrat",
        iso3Code: "MSR",
    },
    {
        isoCode: "MA",
        phoneCode: "212",
        name: "Morocco",
        iso3Code: "MAR",
    },
    {
        isoCode: "MZ",
        phoneCode: "258",
        name: "Mozambique",
        iso3Code: "MOZ",
    },
    {
        isoCode: "MM",
        phoneCode: "95",
        name: "Myanmar",
        iso3Code: "MMR",
    },
    {
        isoCode: "NA",
        phoneCode: "264",
        name: "Namibia",
        iso3Code: "NAM",
    },
    {
        isoCode: "NR",
        phoneCode: "674",
        name: "Nauru",
        iso3Code: "NRU",
    },
    {
        isoCode: "NP",
        phoneCode: "977",
        name: "Nepal",
        iso3Code: "NPL",
    },
    {
        isoCode: "NL",
        phoneCode: "31",
        name: "Netherlands",
        iso3Code: "NLD",
    },
    {
        isoCode: "AN",
        phoneCode: "599",
        name: "Netherlands Antilles",
        iso3Code: "AN",
    },
    {
        isoCode: "NC",
        phoneCode: "687",
        name: "New Caledonia",
        iso3Code: "NCL",
    },
    {
        isoCode: "NZ",
        phoneCode: "64",
        name: "New Zealand",
        iso3Code: "NZL",
    },
    {
        isoCode: "NI",
        phoneCode: "505",
        name: "Nicaragua",
        iso3Code: "NIC",
    },
    {
        isoCode: "NE",
        phoneCode: "227",
        name: "Niger",
        iso3Code: "NER",
    },
    {
        isoCode: "NG",
        phoneCode: "234",
        name: "Nigeria",
        iso3Code: "NGA",
    },
    {
        isoCode: "NU",
        phoneCode: "683",
        name: "Niue",
        iso3Code: "NIU",
    },
    {
        isoCode: "NF",
        phoneCode: "672",
        name: "Norfolk Island",
        iso3Code: "NFK",
    },
    {
        isoCode: "GB-NIR",
        phoneCode: "44",
        name: "Northern Ireland",
        iso3Code: "GBR",
    },
    {
        isoCode: "MP",
        phoneCode: "1-670",
        name: "Northern Mariana Islands",
        iso3Code: "MNP",
    },
    {
        isoCode: "NO",
        phoneCode: "47",
        name: "Norway",
        iso3Code: "NOR",
    },
    {
        isoCode: "OM",
        phoneCode: "968",
        name: "Oman",
        iso3Code: "OMN",
    },
    {
        isoCode: "PK",
        phoneCode: "92",
        name: "Pakistan",
        iso3Code: "PAK",
    },
    {
        isoCode: "PW",
        phoneCode: "680",
        name: "Palau",
        iso3Code: "PLW",
    },
    {
        isoCode: "PS",
        phoneCode: "970",
        name: "Palestine",
        iso3Code: "PSE",
    },
    {
        isoCode: "PA",
        phoneCode: "507",
        name: "Panama",
        iso3Code: "PAN",
    },
    {
        isoCode: "PG",
        phoneCode: "675",
        name: "Papua New Guinea",
        iso3Code: "PNG",
    },
    {
        isoCode: "PY",
        phoneCode: "595",
        name: "Paraguay",
        iso3Code: "PRY",
    },
    {
        isoCode: "PE",
        phoneCode: "51",
        name: "Peru",
        iso3Code: "PER",
    },
    {
        isoCode: "PH",
        phoneCode: "63",
        name: "Philippines",
        iso3Code: "PHL",
    },
    {
        isoCode: "PN",
        phoneCode: "64",
        name: "Pitcairn",
        iso3Code: "PCN",
    },
    {
        isoCode: "PL",
        phoneCode: "48",
        name: "Poland",
        iso3Code: "POL",
    },
    {
        isoCode: "PT",
        phoneCode: "351",
        name: "Portugal",
        iso3Code: "PRT",
    },
    {
        isoCode: "PR",
        phoneCode: "1-787",
        name: "Puerto Rico",
        iso3Code: "PRI",
    },
    {
        isoCode: "QA",
        phoneCode: "974",
        name: "Qatar",
        iso3Code: "QAT",
    },
    {
        isoCode: "RO",
        phoneCode: "40",
        name: "Romania",
        iso3Code: "ROU",
    },
    {
        isoCode: "RU",
        phoneCode: "7",
        name: "Russian Federation",
        iso3Code: "RUS",
    },
    {
        isoCode: "RW",
        phoneCode: "250",
        name: "Rwanda",
        iso3Code: "RWA",
    },
    {
        isoCode: "RE",
        phoneCode: "262",
        name: "Réunion",
        iso3Code: "REU",
    },
    {
        isoCode: "BL",
        phoneCode: "590",
        name: "Saint Barthélemy",
        iso3Code: "BLM",
    },
    {
        isoCode: "SH",
        phoneCode: "290",
        name: "Saint Helena, Ascension and Tristan da Cunha",
        iso3Code: "SHN",
    },
    {
        isoCode: "KN",
        phoneCode: "1-869",
        name: "Saint Kitts and Nevis",
        iso3Code: "KNA",
    },
    {
        isoCode: "LC",
        phoneCode: "1-758",
        name: "Saint Lucia",
        iso3Code: "LCA",
    },
    {
        isoCode: "MF",
        phoneCode: "590",
        name: "Saint Martin",
        iso3Code: "MAF",
    },
    {
        isoCode: "PM",
        phoneCode: "508",
        name: "Saint Pierre and Miquelon",
        iso3Code: "SPM",
    },
    {
        isoCode: "VC",
        phoneCode: "1-784",
        name: "Saint Vincent and the Grenadines",
        iso3Code: "VCT",
    },
    {
        isoCode: "WS",
        phoneCode: "685",
        name: "Samoa",
        iso3Code: "WSM",
    },
    {
        isoCode: "SM",
        phoneCode: "378",
        name: "San Marino",
        iso3Code: "SMR",
    },
    {
        isoCode: "ST",
        phoneCode: "239",
        name: "Sao Tome and Principe",
        iso3Code: "STP",
    },
    {
        isoCode: "SA",
        phoneCode: "966",
        name: "Saudi Arabia",
        iso3Code: "SAU",
    },
    {
        isoCode: "GB-SCT",
        phoneCode: "44",
        name: "Scotland",
        iso3Code: "GBR",
    },
    {
        isoCode: "SN",
        phoneCode: "221",
        name: "Senegal",
        iso3Code: "SEN",
    },
    {
        isoCode: "RS",
        phoneCode: "381",
        name: "Serbia",
        iso3Code: "SRB",
    },
    {
        isoCode: "SC",
        phoneCode: "248",
        name: "Seychelles",
        iso3Code: "SYC",
    },
    {
        isoCode: "SL",
        phoneCode: "232",
        name: "Sierra Leone",
        iso3Code: "SLE",
    },
    {
        isoCode: "SG",
        phoneCode: "65",
        name: "Singapore",
        iso3Code: "SGP",
    },
    {
        isoCode: "SX",
        phoneCode: "1-721",
        name: "Sint Maarten (Dutch part}",
        iso3Code: "SXM",
    },
    {
        isoCode: "SK",
        phoneCode: "421",
        name: "Slovakia",
        iso3Code: "SVK",
    },
    {
        isoCode: "SI",
        phoneCode: "386",
        name: "Slovenia",
        iso3Code: "SVN",
    },
    {
        isoCode: "SB",
        phoneCode: "677",
        name: "Solomon Islands",
        iso3Code: "SLB",
    },
    {
        isoCode: "SO",
        phoneCode: "252",
        name: "Somalia",
        iso3Code: "SOM",
    },
    {
        isoCode: "ZA",
        phoneCode: "27",
        name: "South Africa",
        iso3Code: "ZAF",
    },
    {
        isoCode: "GS",
        phoneCode: "500",
        name: "South Georgia and the South Sandwich Islands",
        iso3Code: "SGS",
    },
    {
        isoCode: "SS",
        phoneCode: "211",
        name: "South Sudan",
        iso3Code: "SSD",
    },
    {
        isoCode: "ES",
        phoneCode: "34",
        name: "Spain",
        iso3Code: "ESP",
    },
    {
        isoCode: "LK",
        phoneCode: "94",
        name: "Sri Lanka",
        iso3Code: "LKA",
    },
    {
        isoCode: "SD",
        phoneCode: "249",
        name: "Sudan",
        iso3Code: "SDN",
    },
    {
        isoCode: "SR",
        phoneCode: "597",
        name: "Suriname",
        iso3Code: "SUR",
    },
    {
        isoCode: "SJ",
        phoneCode: "47",
        name: "Svalbard and Jan Mayen Islands",
        iso3Code: "SJM",
    },
    {
        isoCode: "SZ",
        phoneCode: "268",
        name: "Swaziland",
        iso3Code: "SWZ",
    },
    {
        isoCode: "SE",
        phoneCode: "46",
        name: "Sweden",
        iso3Code: "SWE",
    },
    {
        isoCode: "CH",
        phoneCode: "41",
        name: "Switzerland",
        iso3Code: "CHE",
    },
    {
        isoCode: "SY",
        phoneCode: "963",
        name: "Syrian Arab Republic",
        iso3Code: "SYR",
    },
    {
        isoCode: "TW",
        phoneCode: "886",
        name: "Taiwan",
        iso3Code: "TWN",
    },
    {
        isoCode: "TJ",
        phoneCode: "992",
        name: "Tajikistan",
        iso3Code: "TJK",
    },
    {
        isoCode: "TZ",
        phoneCode: "255",
        name: "Tanzania, United Republic of",
        iso3Code: "TZA",
    },
    {
        isoCode: "TH",
        phoneCode: "66",
        name: "Thailand",
        iso3Code: "THA",
    },
    {
        isoCode: "TL",
        phoneCode: "670",
        name: "Timor-Leste",
        iso3Code: "TLS",
    },
    {
        isoCode: "TG",
        phoneCode: "228",
        name: "Togo",
        iso3Code: "TGO",
    },
    {
        isoCode: "TK",
        phoneCode: "690",
        name: "Tokelau",
        iso3Code: "TKL",
    },
    {
        isoCode: "TO",
        phoneCode: "676",
        name: "Tonga",
        iso3Code: "TON",
    },
    {
        isoCode: "TT",
        phoneCode: "1-868",
        name: "Trinidad and Tobago",
        iso3Code: "TTO",
    },
    {
        isoCode: "TN",
        phoneCode: "216",
        name: "Tunisia",
        iso3Code: "TUN",
    },
    {
        isoCode: "TR",
        phoneCode: "90",
        name: "Turkey",
        iso3Code: "TUR",
    },
    {
        isoCode: "TM",
        phoneCode: "993",
        name: "Turkmenistan",
        iso3Code: "TKM",
    },
    {
        isoCode: "TC",
        phoneCode: "1-649",
        name: "Turks and Caicos Islands",
        iso3Code: "TCA",
    },
    {
        isoCode: "TV",
        phoneCode: "688",
        name: "Tuvalu",
        iso3Code: "TUV",
    },
    {
        isoCode: "UG",
        phoneCode: "256",
        name: "Uganda",
        iso3Code: "UGA",
    },
    {
        isoCode: "UA",
        phoneCode: "380",
        name: "Ukraine",
        iso3Code: "UKR",
    },
    {
        isoCode: "AE",
        phoneCode: "971",
        name: "United Arab Emirates",
        iso3Code: "ARE",
    },
    {
        isoCode: "GB",
        phoneCode: "44",
        name: "United Kingdom",
        iso3Code: "GBR",
    },
    {
        isoCode: "US",
        phoneCode: "1",
        name: "United States",
        iso3Code: "USA",
    },
    {
        isoCode: "UY",
        phoneCode: "598",
        name: "Uruguay",
        iso3Code: "URY",
    },
    {
        isoCode: "UM",
        phoneCode: "1",
        name: "US Minor Outlying Islands",
        iso3Code: "UMI",
    },
    {
        isoCode: "UZ",
        phoneCode: "998",
        name: "Uzbekistan",
        iso3Code: "UZB",
    },
    {
        isoCode: "VU",
        phoneCode: "678",
        name: "Vanuatu",
        iso3Code: "VUT",
    },
    {
        isoCode: "VE",
        phoneCode: "58",
        name: "Venezuela, Bolivarian Republic of",
        iso3Code: "VEN",
    },
    {
        isoCode: "VN",
        phoneCode: "84",
        name: "Vietnam",
        iso3Code: "VNM",
    },
    {
        isoCode: "VG",
        phoneCode: "1-284",
        name: "Virgin Islands, British",
        iso3Code: "VGB",
    },
    {
        isoCode: "VI",
        phoneCode: "1-340",
        name: "Virgin Islands, U.S.",
        iso3Code: "VIR",
    },
    {
        isoCode: "GB-WLS",
        phoneCode: "44",
        name: "Wales",
        iso3Code: "GBR",
    },
    {
        isoCode: "WF",
        phoneCode: "681",
        name: "Wallis and Futuna Islands",
        iso3Code: "WLF",
    },
    {
        isoCode: "EH",
        phoneCode: "212",
        name: "Western Sahara",
        iso3Code: "ESH",
    },
    {
        isoCode: "YE",
        phoneCode: "967",
        name: "Yemen",
        iso3Code: "YEM",
    },
    {
        isoCode: "ZM",
        phoneCode: "260",
        name: "Zambia",
        iso3Code: "ZMB",
    },
    {
        isoCode: "ZW",
        phoneCode: "263",
        name: "Zimbabwe",
        iso3Code: "ZWE",
    },
    {
        isoCode: "AX",
        phoneCode: "358",
        name: "Åland Islands",
        iso3Code: "ALA",
    },
];