<div class="main-container">
  <mat-toolbar id="body" class="root-header" color="primary" [ngClass]="{ zeroheight: yoffset === 0 }">
    <button
      *ngIf="provider?.uid && (provider?.verified || auth.admin || auth.tenant)"
      class="toggle"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <a class="brand-logo" [routerLink]="['/']">BRINGMOS</a>

    <ng-container *ngIf="auth.admin || auth.tenant">
      <svg
        class="slash"
        viewBox="0 0 24 24"
        width="32"
        height="32"
        stroke="currentColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        shape-rendering="geometricPrecision"
      >
        <path d="M16.88 3.549L7.12 20.451"></path>
      </svg>

      <div class="provider-context">
        <a matRipple [matRippleUnbounded]="false" class="provider-link" id="orglink" [routerLink]="['/orders']">
          <img class="provider-pic" *ngIf="provider?.picURL" [src]="provider?.picURL" />
          <span>{{ provider?.name ? provider?.name : 'NO NAME' }}</span></a
        >

        <div class="provider-context-wrapper">
          <button
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            matRipple
            [matRippleUnbounded]="false"
            id="providerswitchbutton"
            class="provider-switch-button"
            (click)="showProviderContext = !showProviderContext"
          >
            <span class="svgspan">
              <svg xmlns=" http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </button>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOffsetY]="10"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayPositions]="positions"
            cdkConnectedOverlayBackdropClass="transparent-backdrop"
            [cdkConnectedOverlayOpen]="showProviderContext"
            (backdropClick)="showProviderContext = false"
            (detach)="showProviderContext = false"
          >
            <bringmos-provider-context
              class="context_card"
              *ngIf="showProviderContext"
              (closedCard)="showProviderContext = false"
              [provider]="provider"
              (setProvider)="setActiveProvider($event)"
            >
            </bringmos-provider-context>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <span class="fill-space"></span>

    <a class="doc-button" mat-stroked-button href="https://docs.bringmos.com" target="_blank">{{
      'sidenav.docs' | translate
    }}</a>

    <div>
      <button class="lng-button" mat-stroked-button [matMenuTriggerFor]="lngmenu">
        <span class="lng-label">{{ language.toUpperCase() }}</span>
      </button>

      <mat-menu class="menu" #lngmenu="matMenu">
        <button class="lng-button" *ngFor="let lng of lngs" (click)="setLanguage(lng.code)" mat-menu-item>
          <span>{{ lng.label }}</span>
        </button>
      </mat-menu>
    </div>

    <div *ngIf="auth.authState$ | async as user" (clickOutside)="closeAccountCard()" class="icon-container">
      <div class="user-profile dontcloseonclick" (click)="showAccount = !showAccount">
        <img
          *ngIf="provider && user.uid === provider.uid && provider.picURL; else defaultAvatar"
          class="profile-button dontcloseonclick"
          mat-mini-fab
          [src]="provider.picURL"
        />
        <ng-template #defaultAvatar>
          <bringmos-avatar [size]="38" [name]="user.displayName ?? ''" [forColor]="user.email ?? ''"></bringmos-avatar>
        </ng-template>
      </div>
      <bringmos-accounts-card
        *ngIf="showAccount && user"
        @accounts
        class="a_card mat-elevation-z5"
        (closed)="showAccount = false"
        [user]="user"
        [provider]="provider"
      >
      </bringmos-accounts-card>
    </div>
  </mat-toolbar>

  <mat-drawer-container class="main-container">
    <mat-drawer
      *ngIf="auth.provider | async as provider"
      #drawer
      fixedInViewport="true"
      class="sidenav"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false && (provider.verified || auth.admin || auth.tenant)"
    >
      <ng-container *ngIf="provider.uid && (provider.verified || auth.admin || auth.tenant); else spinner">
        <div class="side-column-container">
          <ng-container *ngIf="providerService.providerSettings$ | async as setting">
            <div class="control-container">
              <mat-button-toggle-group
                *ngIf="providerService.providerSettings$ | async as setting"
                class="toggle-group"
                [disabled]="!!(setting && setting.balance && setting.balance < 0)"
                [(ngModel)]="status"
                (change)="changeStatus()"
              >
                <mat-button-toggle
                  matTooltip="{{ 'sidenav.close-tooltip' | translate }}"
                  [ngClass]="{ 'red-text': status === 'offline' }"
                  value="offline"
                >
                  <i class="toggle-icon las la-lock"></i>
                </mat-button-toggle>
                <mat-button-toggle
                  matTooltip="{{ 'sidenav.open-tooltip' | translate }}"
                  [ngClass]="{ 'green-text': status === 'online' }"
                  value="online"
                >
                  <i class="toggle-icon las la-lock-open"></i>
                </mat-button-toggle>
              </mat-button-toggle-group>
              <form [formGroup]="timeForm">
                <bringmos-form-field class="time-input">
                  <bringmos-label>
                    {{ (provider?.deliveryAvailable ? 'deliverytimeempty' : 'waitingtimeempty') | translate }}
                  </bringmos-label>
                  <input bringmosInput placeholder="deliverytime" type="time" formControlName="delivery_time" />
                  <mat-icon bringmosSuffix>timelapse</mat-icon>
                </bringmos-form-field>
              </form>
            </div>
            <div *ngIf="setting && !setting.balance_disabled" class="warn-msg-wrapper">
              <bringmos-info-section
                class="warn-msg"
                [type]="InfoSectionType.ALERT"
                *ngIf="setting && setting.balance && setting.balance < 100"
              >
                <span
                  >{{ 'bringmosbalancelow' | translate }}
                  <strong> ({{ (providerService.providerSettings$ | async)?.balance | number: '1.2-2' }}pts)</strong>
                </span>

                <a [routerLink]="['/billing']" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['hide']">{{
                  'bringmosbuyhere' | translate
                }}</a>
              </bringmos-info-section>
            </div>
            <div class="divider">
              <div class="line"></div>
              <span>{{ 'sidenav.restaurant' | translate }}</span>
              <div class="hiddenline"></div>
            </div>

            <div class="nav-list">
              <a
                (click)="closeDrawerHandset()"
                *ngIf="provider.uid"
                class="nav-item"
                [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/']"
              >
                <i class="las la-stream"></i>
                <span class="label">{{ 'sidenav.dashboard' | translate }}</span>
              </a>

              <a
                class="nav-item"
                (click)="closeDrawerHandset()"
                *ngIf="provider.uid"
                [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/menu']"
              >
                <i class="las la-utensils"></i>
                <span class="label">{{ 'sidenav.menu' | translate }}</span>
              </a>

              <a
                class="nav-item"
                (click)="closeDrawerHandset()"
                *ngIf="provider.uid"
                [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/profile']"
              >
                <i class="las la-user-circle"></i>
                <span class="label">{{ 'sidenav.profile' | translate }}</span>
              </a>

              <a
                class="nav-item"
                (click)="closeDrawerHandset()"
                *ngIf="provider.uid && !setting.balance_disabled"
                [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/billing']"
              >
                <i class="las la-wallet"></i>
                <span class="label">{{ 'sidenav.billing' | translate }}</span>
              </a>

              <a
                class="nav-item"
                (click)="closeDrawerHandset()"
                *ngIf="provider.uid"
                [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/settings']"
              >
                <i class="las la-cog"></i>
                <span class="label">{{ 'sidenav.settings' | translate }}</span>
              </a>

              <div class="divider">
                <div class="line"></div>
                <span>{{ 'sidenav.orders' | translate }}</span>
                <div class="hiddenline"></div>
              </div>

              <a
                class="nav-item order"
                (click)="closeDrawerHandset()"
                *ngIf="provider.uid"
                [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/orders']"
              >
                <i class="las la-list"></i>
                <span class="label"></span>
                <div class="c_label">
                  <span>{{ 'sidenav.openorders' | translate }} </span>
                  <span class="fill-space"></span>

                  <ng-container *ngIf="orderService.pendingOrders | async as pendingOrders">
                    <span *ngIf="pendingOrders | orderstatus: 0 as pendingCount; else showPending" class="count"
                      >{{ pendingCount }} {{ 'order.new' | translate }}</span
                    >
                  </ng-container>
                  <ng-template #showPending>
                    <span *ngIf="(orderService.pendingOrders | async)?.length as pendingCount" class="count old">{{
                      pendingCount
                    }}</span>
                  </ng-template>
                </div>
              </a>

              <a
                class="nav-item"
                (click)="closeDrawerHandset()"
                *ngIf="provider.uid"
                [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/orders/all']"
              >
                <i class="las la-list-alt"></i>
                <div class="c_label">
                  <span>{{ 'sidenav.shippedorders' | translate }} </span>
                  <span class="fill-space"></span>
                  <span *ngIf="orderService.shippedOrders | async as shippedCount" class="count old"
                    >{{ shippedCount.length }}{{ shippedCount.length >= 30 ? '+' : '' }}</span
                  >
                </div>
              </a>

              <div class="divider">
                <div class="line"></div>
                <span>{{ 'sidenav.website' | translate }}</span>
                <div class="hiddenline"></div>
              </div>

              <a
                class="nav-item"
                (click)="closeDrawerHandset()"
                *ngIf="provider.link"
                target="_blank"
                href="https://{{ domain }}/{{ provider.link }}"
              >
                <i class="las la-store"></i>
                <span class="label">{{ 'sidenav.website' | translate }}</span>
                <span class="fill-space"></span>
                <i class="external-icon las la-external-link-alt"></i>
              </a>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #spinner>
        <div class="sp-wrapper">
          <mat-spinner diameter="30" class="spinner middle"></mat-spinner>
        </div>
      </ng-template>
    </mat-drawer>

    <mat-drawer-content class="content">
      <div class="router">
        <router-outlet #outlet="outlet"></router-outlet>
        <!-- <bringmos-footer class="footer"></bringmos-footer> -->
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
