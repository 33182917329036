import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class VerifiedGuard {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return (
      this.auth?.admin ||
      this.auth.provider.pipe(
        map((provider) => !!(provider && provider.verified)),
        tap((loggedIn) => {
          if (!loggedIn) {
            this.router.navigate(['/locked']);
          }
        }),
      )
    );
  }
}
