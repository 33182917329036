import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PasswordComponent } from './modules/password/password.component';
import { AdminGuard } from './services/admin.guard';
import { AuthGuard } from './services/auth.guard';
import { VerifiedGuard } from './services/verified.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, VerifiedGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'change-password',
    component: PasswordComponent,
    canActivate: [AuthGuard, VerifiedGuard],
  },
  {
    path: 'providers',
    loadChildren: () => import('./modules/providers/providers.module').then((m) => m.ProvidersModule),
    canActivate: [AdminGuard],
  },
  {
    path: 'locked',
    loadChildren: () => import('./modules/locked/locked.module').then((m) => m.LockedModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
    canActivate: [AuthGuard, VerifiedGuard],
  },
  {
    path: 'drivers',
    loadChildren: () => import('./modules/drivers/drivers.module').then((m) => m.DriversModule),
    canActivate: [AuthGuard, VerifiedGuard],
  },
  {
    path: 'billing',
    loadChildren: () => import('./modules/invoices/invoices.module').then((m) => m.InvoicesModule),
    canActivate: [AuthGuard, VerifiedGuard],
  },
  {
    path: 'menu',
    loadChildren: () => import('./modules/menu/menu.module').then((m) => m.MenuModule),
    canActivate: [AuthGuard, VerifiedGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard, VerifiedGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard, VerifiedGuard],
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./modules/payment-info/payment-info.module').then((m) => m.PaymentInfoModule),
    canActivate: [AuthGuard, VerifiedGuard],
    data: {
      type: 'SUCCESS',
    },
  },
  {
    path: 'payment-cancel',
    loadChildren: () => import('./modules/payment-info/payment-info.module').then((m) => m.PaymentInfoModule),
    canActivate: [AuthGuard, VerifiedGuard],
    data: {
      type: 'CANCEL',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
