import { Injectable } from '@angular/core';
import { MenuItemOrder, Order, Provider, SupportedLanguages } from '@bringmos/types';
import { sha256 } from 'js-sha256';
import { hextorstr, KEYUTIL, KJUR, stob64 } from 'jsrsasign';
import { from, Observable } from 'rxjs';

import { DifferenceType, OrderHelperService } from './order-helper.service';

const qz = require('qz-tray');

export enum PrinterStatus {
  INITIAL = 0,
  INITIALIZED = 1,
  ERROR = 2,
}

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  public printerStatus = PrinterStatus.INITIAL;
  private paymentOptions = [
    {
      display: 'Bar',
      value: 'cash',
    },
    {
      display: 'Bancomat',
      value: 'bancomat',
    },
    {
      display: 'Kreditkarte',
      value: 'credit',
    },
    {
      display: 'Google Pay oder Apple Pay',
      value: 'native',
    },
    {
      display: 'Monni',
      value: 'monni_card',
    },
  ];
  constructor(private helper: OrderHelperService) {
    qz.security.setSignatureAlgorithm('SHA512');
    qz.security.setCertificatePromise((resolve: any, reject: any) => {
      return resolve(
        '-----BEGIN CERTIFICATE-----' +
          'MIIEGzCCAwOgAwIBAgIUXbDysrjmWuvaTIbbFj3wKm13G5UwDQYJKoZIhvcNAQEL' +
          'BQAwgZsxCzAJBgNVBAYTAklUMRIwEAYDVQQIDAlTwoFkdGlyb2wxEDAOBgNVBAcM' +
          'B0JydW5lY2sxFjAUBgNVBAoMDUJyaW5nbW9zIExhYnMxEzARBgNVBAsMCk1hbmFn' +
          'ZW1lbnQxFzAVBgNVBAMMDiouYnJpbmdtb3MuY29tMSAwHgYJKoZIhvcNAQkBFhFp' +
          'bmZvQGJyaW5nbW9zLmNvbTAgFw0yMzA4MjAxNTI4MDJaGA8yMDY2MDEyNTE1Mjgw' +
          'MlowgZsxCzAJBgNVBAYTAklUMRIwEAYDVQQIDAlTwoFkdGlyb2wxEDAOBgNVBAcM' +
          'B0JydW5lY2sxFjAUBgNVBAoMDUJyaW5nbW9zIExhYnMxEzARBgNVBAsMCk1hbmFn' +
          'ZW1lbnQxFzAVBgNVBAMMDiouYnJpbmdtb3MuY29tMSAwHgYJKoZIhvcNAQkBFhFp' +
          'bmZvQGJyaW5nbW9zLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEB' +
          'AND2CEjXBv+84d25r4suSPMEY1sgesf7BE94u4gIF4mjC8tJU1qU/KCTYDvPFoqC' +
          'cteXnzUs8hLeJNenMJTGHnIRsznwj/dxXP4H669qAeS8gdgOzvSAg/0Mj+utim/O' +
          'nB2lx5nBqG5uSs9murqakcuS44Ovqj9N/xSQFbEMQLIGG2QAaTxuKW6L3HDrpWHt' +
          'XrPW4zYbwbtUa1fRgzuSM64NlWqNfHAbZ+zhg6dBaSRU1FPcDQnHyUUvrQ8DNhgg' +
          'u5hRvMFY8DsLiQ9s+Y0sVdUXFR1ejL7BcabWIVwvTim87+9wUzL4yuZs8Kqy7fkL' +
          'T4XaHxo6yTrJXgSdQidk+W8CAwEAAaNTMFEwHQYDVR0OBBYEFBxi7QE1r4luESmQ' +
          'QKvOKYKYpc4TMB8GA1UdIwQYMBaAFBxi7QE1r4luESmQQKvOKYKYpc4TMA8GA1Ud' +
          'EwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBADbUT2A/yGMHlVu6t9LNAxaF' +
          'QF+zrgqTEEUqlpF2WMEd2zLbrOErUGDytuwUsbHerU4lyzdg9MQ2f//8OQcQbj7S' +
          'GnTLcVPHZvFx0iwxAXSwH2mYIImBydR+xqxct4PkbiazLSpHr/rq3YxFELkMf82d' +
          'X3po7jdosKBOR22ccJUgggTU2ecIDPM0TsiUapXJI/u4pCyBOG1WLAxj6r5/h0/e' +
          'DfzCddnyjphIjNR/FPx3Ph8fqyfk/2YQSxCIbr+ygcxQhYTqzSxpTn0/EIzXuLIG' +
          'ziUWhV9Y31L6hEXFM9nzVJGB4hBC24hZ7BWmsh8KMb4Xs00MP7LYOTO/aWKOJE4=' +
          '-----END CERTIFICATE-----',
      );
    });

    qz.security.setSignaturePromise((toSign: any) => {
      return (resolve: any, reject: any) => {
        try {
          const pk = KEYUTIL.getKey(
            '-----BEGIN PRIVATE KEY-----' +
              'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDQ9ghI1wb/vOHd' +
              'ua+LLkjzBGNbIHrH+wRPeLuICBeJowvLSVNalPygk2A7zxaKgnLXl581LPIS3iTX' +
              'pzCUxh5yEbM58I/3cVz+B+uvagHkvIHYDs70gIP9DI/rrYpvzpwdpceZwahubkrP' +
              'Zrq6mpHLkuODr6o/Tf8UkBWxDECyBhtkAGk8bilui9xw66Vh7V6z1uM2G8G7VGtX' +
              '0YM7kjOuDZVqjXxwG2fs4YOnQWkkVNRT3A0Jx8lFL60PAzYYILuYUbzBWPA7C4kP' +
              'bPmNLFXVFxUdXoy+wXGm1iFcL04pvO/vcFMy+MrmbPCqsu35C0+F2h8aOsk6yV4E' +
              'nUInZPlvAgMBAAECggEAOqF15J400Afa5iAmMaqsfEd0a0q7OKZlaLkLAVmn5Q4T' +
              'DpiF40ofchy36UArZnTArV5VJRH14DZlZv75whyuyTnPLIgjlP3BZmOpjR/05Q3B' +
              'bvKEvhTbM+LU9P9N8x4pLqZn0WwFiuq2YehEp3wcQV73KGlfHwEO++GLvJAgDPLo' +
              'tB94mt4XdqCCwMHOODM2MHZhzjyYAlnosUb54/vs5bsMtyOi+fzDM/RLw62skGS2' +
              'jJF6Oc8LVYSrhbyl1rEPvAK3umBrJUznuA32C/RKHW0Dc/uGQyQ3AmxGdCIwnIz/' +
              'ok4J26b962mQIsuy5MfUj7vIB8vL3ScljP4kx38oDQKBgQD1MAz92AQKTGqoQ/h4' +
              'qsCWKWEjM9AxjxieA3LUIOm0Cfbvj7OsxXk1N5zpdtvqtXX1xoZEDOJo+WJ/RctI' +
              'YDtRFUpaCjihyOzfw8sniGUjxBUSqOFOJKfnRiL4ROGVC5jzKEH+dFfRRnxbmMB7' +
              'lYBez6VNxJI3itSOVRyakM8oewKBgQDaLQPxAii6ALJuHwGnUwJJ7VhZvPkmZWlN' +
              '/6rpAVZMn2BvEfgVGXml+iOMjZUfrkX3tjO26djoQUbOPEPxJvxxk3wqMt4Z7EaB' +
              'TH/tLmDpW2y8QTOr8q8kOZqHF4gEWr2Kq9RNxi72Q5VKL9/IN3hn2mew6Od6rq6r' +
              'sibludmSnQKBgGQR5HuGOr/pSG68ryCwsEGZSegQMZzIhoeXaLpjZH7ywjIsOGz/' +
              'e+TYedZfiIpW3XoeOJZ1m8K0ZhsD5V/4xrzlL59oJI0DcIozYysTZBQg52jiPHKk' +
              'S1L9MTCbDGbEgPxdJfIwkdb8Ka1Ivuaxsd8AaLtAy1yX8QDWhAIhsCsZAoGBALWa' +
              'WLiIuouwvtTo3ECB2NreQ/dWU/EA4rl5AbM3WLUoyGVLiLtPXDGq12JMC3+2WSkK' +
              'sVskpVcV1rEd+tPFRsXnJKgfdT4QrNM9cRJk02gKoKTGhRMwS1aU2CpwjNhLC5Bc' +
              '3LfsKfT0ZlS9KsGGYqPXaQ4Zn2w/8Mab4MLBgvgFAoGACJWaJ8hoVxCwdtGoCqdR' +
              'xI4mrXl3Wp4WY8i6VohSI3hCNiUWBav+vE6j4H7tXljAWXuVQKHzp8Ks1+Ka9IRJ' +
              '1/v8C+aGRuuYZIv1KVaSqSisdfwwlQjtQYWhGvtNcUvPE2oQV3lhWNlGmawRCnn8' +
              'mer25wdK2x1x0dKzkF5ECQA=' +
              '-----END PRIVATE KEY-----',
          );
          const sig = new KJUR.crypto.Signature({ alg: 'SHA1withRSA' });
          sig.init(pk);
          sig.updateString(toSign);
          const hex = sig.sign();
          console.log('DEBUG: \n\n' + stob64(hextorstr(hex)));
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });

    qz.api.setSha256Type((data: any) => {
      return sha256(data);
    });
    qz.api.setPromiseType(function promise(resolver: any) {
      return new Promise(resolver);
    });
  }

  init(): any {
    return qz.websocket.connect().catch((error: any) => {
      this.printerStatus = PrinterStatus.ERROR;
      return Promise.reject(error);
    }); // automatically connect to qz when open Orders is opened
  }

  connectAndPrint(
    order: Order,
    provider: Provider,
    printer: string,
    language: SupportedLanguages = SupportedLanguages.DE,
  ): void {
    console.log('connected to qz, set certificate and Signature and printing now');

    let targetTime = '';
    if (order.order_target_date) {
      targetTime =
        ('0' + order.order_target_date.toDate().getHours()).slice(-2) +
        ':' +
        ('0' + order.order_target_date.toDate().getMinutes()).slice(-2) +
        ' Uhr';
    }

    let orderPayment: string[] = [];
    if (order.payment) {
      const payment = this.paymentOptions.find((element) => element.value === order.payment);
      if (payment) {
        orderPayment = payment.display
          ? [
              '\x1B' + '\x45' + '\x0D', // bold
              'Bezahlung: ' + payment.display + '\x0A',
              '\x1B' + '\x45' + '\x0A', // bold off
              '\x1B' + '\x4D' + '\x30', // normal text
            ]
          : [];
      }
    }

    let additional_content = order.additional_content;

    if (additional_content === undefined || additional_content === null) {
      additional_content = ' ';
    }

    let client_address_extra = order.client_address_extra;

    if (order.client_address_extra === undefined || order.client_address_extra === null) {
      client_address_extra = ' ';
    }

    const deliveryPrice = order.delivery_price ? order.delivery_price + '€' : '0€';

    const providerAddress = provider.address && provider.address[language] ? provider.address[language] : '';

    const providerTown = provider.town && provider.town[language] ? provider.town[language] : '';

    const headerData = [
      '\x1B' + '\x40', // init
      '\x1B' + '\x74' + '\x13', // encoding 858
      '\x1B' + '\x61' + '\x31', // center align
      // BRINGMOS DATA --------------------------------------------------------------------------
      // {
      //     type: 'raw',
      //     format: 'image',
      //     flavor: 'file',
      //     data: 'assets/logo/bringmos_logo_black.png',
      //     options: { language: 'escp', dotDensity: 'double' }
      // },
      // '\x0A', // line break
      // '\x1B' + '\x61' + '\x32', // right align
      '\x1B' + '\x21' + '\x30', // em mode on
      'BRINGMOS',
      '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
      '\x0A', // line break
      'www.bringmos.com' + '\x0A', // text and line break
      // '\x0A', // line break
      '\x0A', // line break
      // PROVIDER DATA----------------------------------------------------------------------------
      '\x1B' + '\x45' + '\x0D', // bold on
      provider.name + '\x0A',
      '\x1B' + '\x45' + '\x0A', // bold off
      providerAddress + '\x0A',
      provider.zip + ', ' + providerTown + '\x0A',
      provider.phone + '\x0A',
      // ORDER DATE -------------------------------------------------------------------------------
      order.date?.toDate().toLocaleDateString('de-DE') + '   ' + order.date?.toDate().toLocaleTimeString('de-DE') + '\x0A',
      // '\x0A'
    ];
    // order.client_address_extra ? order.client_address_extra + '\x0A' : '',
    // CLIENT DATA-------------------------------------------------------------------------------
    const clientData = [
      '\x1B' + '\x61' + '\x30', // left align
      order.client_name + '\x0A',
      order.client_address + '\x0A',
      client_address_extra + '\x0A',
      '\x1B' + '\x45' + '\x0D', // bold
      order.client_zip + ' ' + order.client_town + '\x0A',
      '\x1B' + '\x45' + '\x0A', // bold off
      '\x1B' + '\x4D' + '\x30', // normal text
      order.client_phone + '\x0A',
      additional_content + '\x0A',
    ];
    const time = targetTime
      ? [
          '\x1B' + '\x45' + '\x0D', // bold
          'Lieferzeitpunkt: ' + targetTime + '\x0A',
          '\x1B' + '\x45' + '\x0A', // bold off
          '\x1B' + '\x4D' + '\x30', // normal text
        ]
      : [];

    const deliveryPriceSection = [
      // line break
      '\x1B' + '\x45' + '\x0D', // bold
      'Lieferkosten: ' + deliveryPrice + '\x0A',
      '\x1B' + '\x45' + '\x0A', // bold off
      '\x1B' + '\x4D' + '\x30', // normal text
    ]; // line break

    const clientPickupData = [
      '\x1B' + '\x61' + '\x30', // left align
      order.client_name + '\x0A',
      order.client_phone + '\x0A',
      '\x1B' + '\x21' + '\x30', // em mode
      'Selbstabholung',
      '\x0A',
      '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
      '\x1B' + '\x4D' + '\x30', // normal text
      additional_content + '\x0A',
      '\x1B' + '\x45' + '\x0D', // bold
      'Abholung um: ' + targetTime + '\x0A',
      '\x1B' + '\x45' + '\x0A', // bold off
      '\x1B' + '\x4D' + '\x30', // normal text
    ];

    const clientLocalData = [
      '\x1B' + '\x61' + '\x30', // left align
      order.client_name + '\x0A',
      order.client_phone + '\x0A',
      '\x1B' + '\x21' + '\x30', // em mode
      'Zum Tisch: ' + (order.table_name || ''),
      '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
      '\x1B' + '\x4D' + '\x30', // normal text
      additional_content + '\x0A',
      '\x1B' + '\x4D' + '\x30', // normal text
    ];
    // CLIENTS ORDER -----------------------------------------------------------------------------

    const menuData = ['_____________________' + '\x0A', '\x0A'];

    if (order.menuItems) {
      order.menuItems.forEach((menuItem: MenuItemOrder) => {
        const item = menuItem.count + 'x  ' + menuItem.names[language];

        const itemPrice =
          menuItem.totalprice.toLocaleString('de-DE', {
            style: 'decimal',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) + ' EUR';

        let itemCommentExtra;
        if (menuItem.extras) {
          itemCommentExtra = menuItem.extras;
        }

        let itemExtras = '';
        if (menuItem.extraItems && menuItem.extraItems.length) {
          for (const extra of menuItem.extraItems) {
            if (extra.count !== 0 && extra.count !== undefined && extra.count !== null) {
              itemExtras += extra.count + 'x ';
            }

            itemExtras += extra.names[language];

            if (extra.price !== undefined) {
              itemExtras += '(' + extra.price + ')';
            }

            itemExtras += '\x0A';
          }
        }

        // count and item left align
        menuData.push('\x1B' + '\x21' + '\x30');
        menuData.push('\x1B' + '\x61' + '\x30'); // left
        menuData.push(item);
        menuData.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A'); // em mode off

        // extras left align and small text
        menuData.push('\x0A');
        menuData.push('\x1B' + '\x45' + '\x0D'); // bold text
        menuData.push('\x1B' + '\x61' + '\x30'); // left

        if (menuItem.custom) {
          if (
            menuItem.initial_ingredients &&
            menuItem.initial_ingredients[language] &&
            menuItem.multi_ingredients &&
            menuItem.multi_ingredients[language]
          ) {
            menuData.push('\x1B' + '\x21' + '\x12'); // em mode on
            menuData.push('\x1B' + '\x4D' + '\x29'); // bigger
            menuData.push(this.helper.getIngredientsDifferenceString(menuItem, language, DifferenceType.ADDED));
            menuData.push('\x0A');
            menuData.push(this.helper.getIngredientsDifferenceString(menuItem, language, DifferenceType.REMOVED));
            menuData.push('\x0A');
            menuData.push('\x1D' + '\x21' + '\x00'); // standard font size
            menuData.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A'); // em mode off
          } else if (menuItem.multi_ingredients && menuItem.multi_ingredients[language]) {
            menuData.push('\x1B' + '\x21' + '\x12'); // em mode on
            menuData.push('\x1B' + '\x4D' + '\x29'); // bigger
            menuData.push(menuItem.multi_ingredients[language]);
            menuData.push('\x1D' + '\x21' + '\x00'); // standard font size
            menuData.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A'); // em mode off
          }
        }

        if (menuItem.slice_data && menuItem.slice_data.length > 0) {
          menuData.push('\x1B' + '\x21' + '\x12'); // em mode on
          menuData.push('\x1B' + '\x4D' + '\x29'); // bigger
          menuItem.slice_data.forEach((slice, i) => {
            if (slice.from_menu_item && slice.from_menu_item.names && slice.from_menu_item.names[language]) {
              menuData.push(`Teil ${++i}: ${slice.from_menu_item.names[language]}`);
              menuData.push('\x0A');
            } else if (slice.multi_ingredients && slice.multi_ingredients[language]) {
              menuData.push(`Teil ${++i}: ${slice.multi_ingredients[language]}`);
              menuData.push('\x0A');
            }
          });
          menuData.push('\x1D' + '\x21' + '\x00'); // standard font size
          menuData.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A'); // em mode off
        }

        if (menuItem.combo_data && menuItem.combo_data.selection && menuItem.combo_data.selection.length > 0) {
          menuData.push('\x1B' + '\x21' + '\x12'); // em mode on
          menuData.push('\x1D' + '\x21' + '\x00'); // standard font size

          menuItem.combo_data.selection.forEach((combo, i) => {
            if (combo && combo.names && combo.names[language]) {
              menuData.push(`${combo.count ? combo.count : 1}x: ${combo.names[language]}`);
              menuData.push('\x0A');
            }
            if (combo && combo.extraItems && combo.extraItems.length) {
              console.log(combo.extraItems);
              combo.extraItems.forEach((extra) => {
                menuData.push(
                  `${extra.is_multiple ? (extra.count ? `${extra.count}x` : `  1x`) : '  '}${extra.names[language]}`,
                );
                menuData.push('\x0A');
              });
            }
          });
          menuData.push('\x1D' + '\x21' + '\x00'); // standard font size
          menuData.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A'); // em mode off
        }

        if (itemExtras) {
          menuData.push('\x1B' + '\x21' + '\x12'); // em mode on
          menuData.push('\x1B' + '\x4D' + '\x29'); // bigger
          menuData.push(itemExtras);
          menuData.push('\x1D' + '\x21' + '\x00'); // standard font size
          menuData.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A'); // em mode off
        }
        if (itemCommentExtra) {
          menuData.push('\x1B' + '\x21' + '\x12'); // em mode on
          menuData.push('\x1B' + '\x4D' + '\x29'); // little bigger
          menuData.push(itemCommentExtra);
          menuData.push('\x1D' + '\x21' + '\x00'); // standard font size
          menuData.push('\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A'); // em mode off
          menuData.push('\x0A');
        }
        // price right align
        // menuData.push('\x0A');
        menuData.push('\x1B' + '\x4D' + '\x30'); // normal text
        menuData.push('\x1B' + '\x61' + '\x32'); // right
        menuData.push(itemPrice);
        menuData.push('\x0A');
      });
    }
    menuData.push('-----------------------------------------------' + '\x0A');
    menuData.push('\x1B' + '\x4D' + '\x30'); // normal text
    menuData.push('\x1B' + '\x45' + '\x0D'); // bold
    menuData.push('\x1B' + '\x61' + '\x30'); // left align
    menuData.push('\x1B' + '\x61' + '\x32' + 'Gesamtpreis: '); // right
    menuData.push(
      order.totalprice?.toLocaleString('de-DE', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + ' EUR',
    );
    menuData.push('\x1B' + '\x45' + '\x0A'); // bold off
    menuData.push('\x0A');
    // Disclaimer
    const disclaimerData = [
      '\x1B' + '\x61' + '\x30',
      '_______________________________________________' + '\x0A',
      '\x1B' + '\x4D' + '\x31', // small text
      'Preis kann für Sonderwünsche variieren' + '\x0A',
      '\x1B' + '\x4D' + '\x30', // normal text
      'Dieser Beleg gilt NICHT als Kassazettel',
      '\x1B' + '\x61' + '\x30',
      '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',
      '\x1B' + '\x69',
    ];
    let printData = [];
    if (order.pickup) {
      printData = headerData
        .concat(clientPickupData)
        // .concat(time) is included in pickupdata
        .concat(orderPayment)
        .concat(menuData)
        .concat(disclaimerData);
    } else if (order.local) {
      printData = headerData
        .concat(clientLocalData)
        // .concat(time)
        .concat(orderPayment)
        .concat(menuData)
        .concat(disclaimerData);
    } else {
      printData = headerData
        .concat(clientData)
        .concat(time)
        .concat(orderPayment)
        .concat(menuData)
        .concat(deliveryPriceSection)
        .concat(disclaimerData);
    }
    this.printData(printer, printData);
  }

  printData(printer: string, data: any) {
    if (!printer) {
      printer = 'BRINGMOS PRINTER USB';
    }
    const config = qz.configs.create(printer, { encoding: 'cp858' });
    // cp1252 prints € but not äöüß   // cp858 prints äöüß not € // cp775 prints € as ?
    qz.print(config, data).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  removePrinter(): void {
    qz.websocket.disconnect();
  }

  connect(): Promise<any> {
    return qz.websocket.connect();
  }

  getPrinters(): Observable<any> {
    return from(
      qz.printers.find().catch((error: any) => {
        this.printerStatus = PrinterStatus.ERROR;
        return Promise.reject(error);
      }),
    );
  }

  //   getPrinter(printerName: string): Observable<{}> {

  //       qz.printers.find(printerName).catch((error: any) => {
  //         this.printerStatus = PrinterStatus.ERROR;
  //         return Promise.reject(error);
  //       })

  //   }
}
