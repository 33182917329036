import { Timestamp } from 'firebase/firestore';

import { LanguageMap } from './menu_item';

export interface Ingredient {
  id?: string;
  providerId?: string;
  available: boolean;
  names: LanguageMap;
  category_codes: string[];
  image_code?: string;
  image_url?: string;
  price: number;
  date: Timestamp;
}
