import { Timestamp } from 'firebase/firestore';

import { LanguageMap } from './menu_item';

export interface Extra {
  id?: string;
  providerId?: string;
  names: LanguageMap;
  price: number;
  category_code: string;
  is_multiple: boolean;
  date: Timestamp;
  groups: LanguageMap;
  available: boolean;
}

export interface ExtraOrder extends Extra {
  count: number;
  checked: boolean;
}
