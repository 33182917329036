import { Timestamp } from 'firebase/firestore';

export interface Statistic {
  id?: string;
  providerId?: string;
  start: Timestamp;
  end: Timestamp;

  orders_count: number;
  orders_accepted: number; // 1
  orders_sent: number; // 2
  orders_discarded: number; // 3
  orders_avg_price: number;
  orders_total_price: number;
  order_max_price: number;
  order_min_price: number;
  most_popular_orders: {
    order_id: string;
    count: number;
    client_id: string;
    client_concat_name: string;
  };

  menuitems_count: number;
  menuitems_avg_price: number;
  menuitems_avg_price_of_order: number;
  menuitems_total_price: number;
  most_popular_menuitems: [{ menuitem_id: string; count: number }];

  extras_count: number;
  extras_avg_price: number;
  extras_total_price: number;
  p_extra_in_menuitem: number;
  extras_avg_price_or_menuitem: number;
  extra_avg_price: number;
  most_popular_extras: [{ extra_id: string; count: number }];

  ingredients_count: number;
  ingredients_avg_price: number;
  ingredients_total_price: number;
  most_popular_ingredients: [{ ingredient_id: string; count: number }];

  custom_menuitems_count: number;
  custom_menuitems_avg_price: number;
  custom_menuItems_most_popular_ings: [
    { ingredient_id: string; count: number }
  ];

  max_orders_of_client: number;
  avg_orders_of_client: number;
  most_pupular_clients: [
    { clientid: string; order_count: number; client_concat_name: string }
  ];

  total_balance_needed: number;
  max_balance: number;
  min_balance: number;
  max_balance_recharge: number;
  min_balance_recharge: number;
  avg_balance_recharge: number;
  avg_balance_before_recharge: number;
}
