<div
  class="avatar-circle dontcloseonclick"
  matRipple
  [matRippleColor]="'#ffffff20'"
  [matRippleUnbounded]="false"
  [ngStyle]="{
    height: size + 'px',
    width: size + 'px',
    fontSize: fontSize - 1 + 'px',
    fontWeight: fontWeight,
    background: (themeService.isDarkTheme | async) === false ? color[300] : color[900],
    color: (themeService.isDarkTheme | async) === false ? color[900] : color[200]
  }"
  [ngClass]="{ active: active }"
>
  <ng-container *ngIf="isMachine; else human">
    <i class="machine-icon las la-robot"></i>
  </ng-container>
  <ng-template #human>
    <span class="dontcloseonclick">{{ credentials }}</span>
    <img class="dontcloseonclick" *ngIf="avatarUrl" [src]="avatarUrl" (error)="errorHandler($event)" />
  </ng-template>
</div>
