import { Timestamp } from 'firebase/firestore';

export interface Printer {
  uid?: string;
  providerId: string;
  printers: {
    [printername: string]: Device;
  };
  changed_date?: Timestamp;
}

export interface Device {
  active: boolean;
  category_filter_enabled: boolean;
  category_codes: string[];
  color: string;
  ip: string;
  port: number;
  forQz: boolean;
}
