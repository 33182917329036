import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CategoryAvatarPipe } from '../../pipes/category-avatar.pipe';
import { CategoryPipe } from '../../pipes/category.pipe';
import { IngredientsAddedPipe } from '../../pipes/ingredients-added.pipe';
import { IngredientsRemovedPipe } from '../../pipes/ingredients-removed.pipe';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';

@NgModule({
  declarations: [CategoryPipe, LocalizedDatePipe, CategoryAvatarPipe, IngredientsRemovedPipe, IngredientsAddedPipe],
  imports: [CommonModule],
  exports: [CategoryPipe, LocalizedDatePipe, CategoryAvatarPipe, IngredientsRemovedPipe, IngredientsAddedPipe],
})
export class PipesModule {}
