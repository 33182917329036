export interface Invoice {
    providerId: string; // used for query
    email: string;
    stripe_customer?: string;
    customerData?: any;
    // email: string;
    items: [StripeOrderItem];
    // customer_tax_exempt: 'reverse',
    daysUntilDue?: number; // defaults to 7 days
    stripeInvoiceUrl?: string;
    stripeInvoiceRecord?: string;
    stripeInvoiceId?: string;
    stripeInvoiceStatus?: string;
    lastStripeEvent?: string;
    default_tax_rates?: string[];
    transfer_data?: {
        destination: string;
        amount?: number;
    };
}

interface StripeOrderItem {
    amount: number;
    currency: string;
    quantity?: number;
    description: string;
    tax_rates?: string[];
}