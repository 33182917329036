import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '@bringmos/types';

@Pipe({
  name: 'orderstatus',
})
export class OrderStatusPipe implements PipeTransform {
  transform(orders: Order[], status: number): number {
    if (orders?.length) {
      return orders.filter((order) => order.status === status).length;
    } else {
      return 0;
    }
  }
}
