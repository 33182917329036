import { Timestamp } from 'firebase/firestore';

import { Interval } from './interval';
import { SupportedLanguages } from './language';
import { LanguageMap } from './menu_item';

export interface ProviderDescription {
  times?: string;
  delivery_special?: string;
  delivery_locs?: string;
  description?: string;
  tagline?: string;
  language?: string;
}

export enum ProviderCategory {
  GROCERY = 'grocery',
  RESTAURANT = 'restaurant',
}

export interface Provider {
  uid: string;
  email: string;
  name?: string;
  contact_name?: string;
  contact_phone?: string;
  picURL?: string;
  open_weekdays?: string;
  descriptions?: Array<ProviderDescription>;
  language?: SupportedLanguages;
  menuLanguages?: SupportedLanguages[];
  link?: string;
  website?: string;
  phone?: string;
  address?: LanguageMap;
  town?: LanguageMap;
  vat?: string;
  zip?: number;
  country?: string;
  coverURL?: string;
  only_pickup?: boolean;
  pickupAvailable?: boolean;
  deliveryAvailable?: boolean;
  localAvailable?: boolean;
  local_table_names?: { [tableName: string]: boolean };
  store_visible?: boolean;
  store_acceptOrders?: boolean;
  store_menu_only?: boolean;
  type?: LanguageMap;
  min_order_price?: number;
  delivery_time?: string;
  delivery_costs?: string;
  category: ProviderCategory;
  creditcard_fee: number;
  creditcard_min_price: number;
  balance?: number;
  customItemsAvailable: boolean;
  free_delivery_from?: number;
  delivery_locations?: Location[];
  ingredients_initialized?: boolean;
  lat?: number;
  lng?: number;
  messagingToken?: string;
  rank?: number;
  specials?: LanguageMap;
  tagline?: LanguageMap;
  description?: LanguageMap;
  payment_methods?: {
    cash?: boolean;
    bancomat?: boolean;
    credit?: boolean;
    paypal?: boolean;
  };
  rating: {
    avg: number;
    count: number;
    sum: number;
  };
  verified?: boolean;
  creation_date?: Timestamp;
  businessHours: BusinessHours;
}

export interface BusinessHours {
  active?: boolean;
  [day: number]: Interval[]; // day starting at 1 to 7
}

export interface ProviderSetting {
  balance: number;
  bringmos_fee_percent: number;
  pda_fee_percent: number;
  providerId: string;
  stripeAccountId: string;
  checkout_enabled: boolean;
  stripe_customer: string;
  chartsDisabled: boolean;
  balance_disabled: boolean;
}

export interface Location {
  names?:
    | {
        [language: string]: string;
      }
    | {};
  price?: number;
}

export interface ProviderMenuPreferences {
  category_sorting: { [category_code: string]: number };
  last_modified: Timestamp;
}

export interface ProviderDriverPreferences {
  enabled: boolean;
  last_modified: Timestamp;
}

export interface ProviderOrderPreferences {
  sound_enabled: boolean;
  table_columns: Array<{
    column: string;
    enabled: boolean;
  }>;
  last_modified: Timestamp;
}
