// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCm1bIqnqtZ8l4xJbZMqtA1ETPGNh1kJ_Q',
    authDomain: 'bringmos-dev.firebaseapp.com',
    projectId: 'bringmos-dev',
    storageBucket: 'bringmos-dev.appspot.com',
    messagingSenderId: '478752332368',
    appId: '1:478752332368:web:71b46a941cc53bb4988f55',
    measurementId: 'G-JXJCMECL94',
  },
  stripe: {
    pk: 'pk_test_51H00hJFZJEzjyGdvohSVLJEu8iIzpACE6Cafts8L5IMkgXOUUTb0nEkQukZaFx6dOkjxuB7XEQ7Q9Fiez0LnKNIG0032rKh59v',
  },
  printing: {
    privatekey:
      '-----BEGIN PRIVATE KEY-----' +
      'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQD1yau2zb5viYT5' +
      'P0VoADJT8kTZnPouOxk//kD2nIrcSuR9GrKAOqdPbcY+PCl064adfD3TzNij+a8L' +
      'SasOlnntdlSA35plPV7QsPntpAd1AD6OmGgwC2ul5kIKhrPWYWb1jfxhm73rH5UD' +
      'pbp/xH+pxqmVDX9ogQI/sGwNOaLcMubz+sOiFUfG3VrjZ9UAdLze+qKhBQ3onFyj' +
      'kMG4BlDdHwPP+3zqF2Ukj/rmZGlCS4z7kYQ9u7q/6A7FApbGTjcQ6S3it8CL9HLI' +
      'IflZ+K1sY//85eED/meCgiV9vgTjqESc1s3s6/GgWReODtqKzWw4kKO4Ka3DDVb0' +
      'h/vDkkjZAgMBAAECggEAFMyuNVgP54xxrk4GZ5isqaH05Z19JnBl4J55jOUOczn7' +
      'QAMEXAmaowQdJKMetwA/chBTgxrwsl+syn2/dgqR4uCCoi1J+YtJ/MsdO1kIMISG' +
      'xrZrCrXMtEtaB14C1f+EIP+U/gWZ+s2/ZIGf2LQeQZXIQA7WW1efgxhA7JWWDKAv' +
      'qkUe7NNWo9BQwE08+RpEG9mi1kHvf/3beInsJMqy/evN/OdRlrMzDCo5ZhIHstEI' +
      'hMY/YETYTpTHsv78Fkc795fEcEn7uTKIZphPgvCH488rQB3EcCllBjA42pe6RQOh' +
      'Wbmyzki931EM6QdzzJNZsms87xM3kUtqrCxZhWQpAQKBgQD9CwHo5ZLuzwUzWrjm' +
      'ft8U7NjEggjCrS9VCmsCT/fbXHNqh7KptQ74baz7OF3MjNYuUsBVf9JFZuyaSme8' +
      '+nTDLID6jk0hT43usiMXhEH1DdMdOy3xs0K8eyjpsgDUJ0LSX1tSFJfObc3qYZGy' +
      'YquAl6OdZn2MPQiRf7u9Y3KaCQKBgQD4qPV5zdpaHXdg1dzBWLj1TblsY6KSOohJ' +
      '3WYnmfZIDZq1aCqdMyZwnwJ/5aDLkVP7Jg7v2VIHbCJmjJNSrPQ2vS8NwN8gtGcu' +
      'RQueA98GdJTOesVLoy51OeG5lrigf+6deaAzvq9XWtgo7Gclkg+4lkQcdgn9T5jx' +
      'yca3+KYsUQKBgQCJXIhU3Qq+CDNShVUKCqUevtONGOmYhFrFVzpvrullcvJUSgV6' +
      'iobt5NuaJCcG+wzpaJJv10tV0WFvACUAORVZPbdmTAkipATwJlNWWL1VQYPwwkdW' +
      'hXEcz96NvcslX4qumIUCMZuWu5SkPwLQSy++RpLSxugwUJFuKmDHlkyHwQKBgDmb' +
      'cxtf7Hk6IGEHytfkRR1Xf2C0CILWGqL6LuN30M3KkQ/77Pt0UTCANiUJPuJKbele' +
      'XNGGSHG9xI57fNoHhaB03cTS7R53jPv0NQfLn8ywD5VybejjCi1QVpIXb/iHCvHi' +
      'rpGuWJLlUfTRJ+hVWV0LOwZY9mGAIk/JzkbqzRyxAoGAQLVuA0xYmbk8KF8svOnv' +
      'UmWeOW6qjqV7lEIo4BNd5K1C52qa4pd9+aKkunWo3IcHDPX82Ran01FEASBlUNd9' +
      '/JCtbq8ruLMy9wNvI2JmLMgaSJ5Mbn0qNZF2R6i5keCEbqgRw5v5KrnmaauiRN/0' +
      'w4d7l1ifxTcxt1CpmYRcwvE=' +
      '-----END PRIVATE KEY-----',
    certificate:
      '-----BEGIN CERTIFICATE-----' +
      'MIIEJzCCAw+gAwIBAgIUJnLmdLllzObZ81PShilTZf2UrX8wDQYJKoZIhvcNAQEL' +
      'BQAwgaExCzAJBgNVBAYTAklUMRAwDgYDVQQIDAdCT0xaQU5PMRAwDgYDVQQHDAdC' +
      'UlVOSUNPMREwDwYDVQQKDAhCUklOR01PUzEQMA4GA1UECwwHV0VCX0FQUDEaMBgG' +
      'A1UEAwwRKi5hcHAtanVua2llcy5jb20xLTArBgkqhkiG9w0BCQEWHmVtbWF4LmNy' +
      'ZWF0aXZlc3R1ZGlvQGdtYWlsLmNvbTAgFw0xODEwMjcwOTU5MzZaGA8yMDUwMDQy' +
      'MTA5NTkzNlowgaExCzAJBgNVBAYTAklUMRAwDgYDVQQIDAdCT0xaQU5PMRAwDgYD' +
      'VQQHDAdCUlVOSUNPMREwDwYDVQQKDAhCUklOR01PUzEQMA4GA1UECwwHV0VCX0FQ' +
      'UDEaMBgGA1UEAwwRKi5hcHAtanVua2llcy5jb20xLTArBgkqhkiG9w0BCQEWHmVt' +
      'bWF4LmNyZWF0aXZlc3R1ZGlvQGdtYWlsLmNvbTCCASIwDQYJKoZIhvcNAQEBBQAD' +
      'ggEPADCCAQoCggEBAPXJq7bNvm+JhPk/RWgAMlPyRNmc+i47GT/+QPacitxK5H0a' +
      'soA6p09txj48KXTrhp18PdPM2KP5rwtJqw6Wee12VIDfmmU9XtCw+e2kB3UAPo6Y' +
      'aDALa6XmQgqGs9ZhZvWN/GGbvesflQOlun/Ef6nGqZUNf2iBAj+wbA05otwy5vP6' +
      'w6IVR8bdWuNn1QB0vN76oqEFDeicXKOQwbgGUN0fA8/7fOoXZSSP+uZkaUJLjPuR' +
      'hD27ur/oDsUClsZONxDpLeK3wIv0csgh+Vn4rWxj//zl4QP+Z4KCJX2+BOOoRJzW' +
      'zezr8aBZF44O2orNbDiQo7gprcMNVvSH+8OSSNkCAwEAAaNTMFEwHQYDVR0OBBYE' +
      'FNaEIBl47x7ieu7rmNtK+amQkm0XMB8GA1UdIwQYMBaAFNaEIBl47x7ieu7rmNtK' +
      '+amQkm0XMA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBANSGSRDw' +
      'wxeH7monfdFU5FU+GKO7EtTBZ0BRMyGnSEorvgLNFyFZlT+qfiEMfD4uZ0dzbVbt' +
      'GvMQjmWLtwodZmh+74dYfNkozk3U0QL3I994CbaFPwAA6DA0JyNcshMv3AWQYdYW' +
      'mge9e1B2r4dDdLkPloBrwLVIJ4A+krB2yJaEGsmNIGXShGgSQD94vHLm7gi4oYg4' +
      'TADz1t11cR7UUfTEnr2dAZqsC3skGi4cA6xyUTidjvleeFo/+XtUEM9VEARcd1ej' +
      '414ZrxNNE+dyTjwzl8mdPmSzAKH1zMS4FzM6ilDicAdG5mlIFUcE7LpnxeMnHtue' +
      'xro5KylV9GbFlTw=' +
      '-----END CERTIFICATE-----',
  },
  STRIPE_CLIENT_ID: 'ca_HZ9V2EpH9b1LGeKXjSCi3xfuHfU9ffwd',
};
