import { Injectable } from '@angular/core';
import { MenuItem, SupportedLanguages } from '@bringmos/types';

export enum DifferenceType {
  ADDED = 0,
  REMOVED = 1,
}

@Injectable({
  providedIn: 'root',
})
export class OrderHelperService {
  getIngredientsDifferenceString(
    menuItem: MenuItem,
    language: SupportedLanguages = SupportedLanguages.DE,
    type: DifferenceType,
  ): string {
    let labelWith = 'with';
    let labelWithout = 'without';
    let labelAnd = 'and';
    switch (language) {
      case SupportedLanguages.DE:
        labelWith = 'mit';
        labelWithout = 'ohne';
        labelAnd = 'und';
        break;
      case SupportedLanguages.IT:
        labelWith = 'con';
        labelWithout = 'senza';
        labelAnd = 'e';
        break;
    }

    if (menuItem.added_ingredients || menuItem.removed_ingredients) {
      if (type === DifferenceType.ADDED && menuItem.added_ingredients) {
        const addedIngredients = Object.keys(menuItem.added_ingredients).map((key: string) => {
          if (key && language && menuItem.added_ingredients && menuItem.added_ingredients[key][language]) {
            return menuItem.added_ingredients[key][language];
          } else {
            return '';
          }
        });
        const added = `${labelWith} ${addedIngredients.join(', ').replace(/, ([^,]*)$/, ` ${labelAnd} $1`)}`;
        return addedIngredients && addedIngredients.length > 0 ? added + ' ' : '';
      } else if (type === DifferenceType.REMOVED && menuItem.removed_ingredients) {
        const removedIngredients = Object.keys(menuItem.removed_ingredients).map((key) => {
          if (key && language && menuItem.removed_ingredients && menuItem.removed_ingredients[key][language]) {
            return menuItem.removed_ingredients[key][language];
          } else {
            return '';
          }
        });
        const removed = `${labelWithout} ${removedIngredients.join(', ').replace(/, ([^,]*)$/, ` ${labelAnd} $1`)}`;
        return removedIngredients && removedIngredients.length > 0 ? removed + ' ' : '';
      } else {
        return '';
      }
    } else {
      if (type === DifferenceType.ADDED) {
        if (menuItem.multi_ingredients && menuItem.multi_ingredients[language]) {
          const multiIngredients = menuItem.multi_ingredients[language].split(', ');
          const initialIngredients = menuItem.initial_ingredients ? menuItem.initial_ingredients[language].split(', ') : [];
          if (menuItem.initial_ingredients && multiIngredients) {
            const addedIngredients = multiIngredients.filter((el: string) => !initialIngredients.includes(el));
            const added = `${labelWith} ${addedIngredients.join(', ').replace(/, ([^,]*)$/, ` ${labelAnd} $1`)}`;
            return addedIngredients && addedIngredients.length > 0 ? added + ' ' : '';
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else if (type === DifferenceType.REMOVED) {
        if (menuItem.multi_ingredients && menuItem.multi_ingredients[language]) {
          const multiIngredients = menuItem.multi_ingredients[language].split(', ');
          const initialIngredients = menuItem.initial_ingredients ? menuItem.initial_ingredients[language].split(', ') : [];
          if (menuItem.initial_ingredients && multiIngredients) {
            const removedIngredients = initialIngredients.filter((el: string) => !multiIngredients.includes(el));
            const removed = `${labelWithout} ${removedIngredients.join(', ').replace(/, ([^,]*)$/, ` ${labelAnd} $1`)}`;
            return removedIngredients && removedIngredients.length > 0 ? removed + ' ' : '';
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  }
}
