import { Injectable } from '@angular/core';
import {
  collection,
  collectionSnapshots,
  doc,
  Firestore,
  getDocs,
  limit,
  orderBy,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Order, Provider } from '@bringmos/types';
import { query } from 'firebase/firestore';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public readonly pendingOrders: Observable<Order[]> = of([]);
  public readonly shippedOrders: Observable<Order[]> = of([]);

  constructor(public firestore: Firestore, private authService: AuthService) {
    this.pendingOrders = this.authService.provider.pipe(
      switchMap((provider: Provider | undefined) => {
        if (provider) {
          return this.getOpenOrdersOfProvider(provider.uid);
        } else {
          return of([]);
        }
      }),
    );

    this.shippedOrders = this.authService.provider.pipe(
      switchMap((provider: Provider | undefined) => {
        if (provider) {
          return this.getPreviousAndCancelledOrdersOfProvider(provider.uid);
        } else {
          return of([]);
        }
      }),
    );
  }

  private getPreviousAndCancelledOrdersOfProvider(providerId: string): Observable<Order[]> {
    const col = collection(this.firestore, 'Orders');
    const ref = query(
      col,
      where('providerId', '==', providerId),
      where('status', '>=', 2),
      where('status', '<', 5),
      orderBy('status', 'asc'),
      orderBy('date', 'desc'),
      limit(30),
    );

    return collectionSnapshots(ref).pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.data() as Order;
          const id = a.id;
          return { ...data, id };
        }),
      ),
    );
  }

  private getOpenOrdersOfProvider(providerId: string): Observable<Order[]> {
    const col = collection(this.firestore, 'Orders');
    const ref = query(
      col,
      where('providerId', '==', providerId),
      where('status', '<=', 1),
      orderBy('status', 'asc'),
      orderBy('date', 'desc'),
      limit(200),
    );

    return collectionSnapshots(ref).pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.data() as Order;
          const id = a.id;
          return { ...data, id };
        }),
      ),
    );
  }

  public updateOrder(order: any): Promise<void> {
    const orderCol = collection(this.firestore, 'Orders');
    const orderDoc = doc(orderCol, order.id);

    return updateDoc(orderDoc, order, { merge: true });
  }
}
