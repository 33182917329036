import { Timestamp } from 'firebase/firestore';

import { Allergic } from './allergics';
import { ExtraOrder } from './extra';
import { Ingredient } from './ingredient';
import { Interval } from './interval';
import { SupportedLanguages } from './language';

export type LanguageMap = { [value in SupportedLanguages]: string };

export type LanguageTagMap = { [value in SupportedLanguages]: string[] };

export interface MenuItem {
  imported?: boolean;
  available: boolean;
  id: string;
  providerId?: string;
  base?: boolean;
  custom?: boolean;
  count?: number;
  names: LanguageMap;

  ingredientIds?: string[]; // new ids
  initialIngredientIds?: string[]; // old ids
  multi_ingredients?: LanguageMap; // new ing desc map
  initial_ingredients?: LanguageMap; // old ing desc map

  descriptions: LanguageMap; // old desc map

  added_ingredients?: { [ingId: string]: LanguageMap };
  removed_ingredients?: { [ingId: string]: LanguageMap };

  unavailableIngredients?: {
    [ingId: string]: Ingredient;
  };
  time_limited?: TimeLimited;

  category_code: string;
  category_order: number;

  subcategories?: LanguageMap;
  price: number;
  imageUrl?: string;
  multi_tags?: LanguageTagMap;
  allergics?: Array<Allergic>;
  // allergicIds?: string[];
  date: Timestamp;
  order: number;

  slice_data?: Array<{
    multi_ingredients: LanguageMap;
    ingredients: Ingredient[];
    from_menu_item: MenuItem;
  }>;

  combo_config: ComboConfig[];
  combo_data: {
    selection: Partial<MenuItemOrder>[];
  };

  slices?: {
    available: boolean;
    min: number;
    max: number;
  };
}

export interface ComboConfig {
  ids: string[];
  category_codes: string[];
  max_selection: number;
}

export interface TimeLimited {
  active: boolean;
  mode?: 'monthly' | 'weekly' | 'daily';
  monthly?: {
    [day: number]: Interval[];
  };
  weekly?: {
    [day: number]: Interval[];
  };
  daily?: Interval[];
}

export interface MenuItemOrder extends MenuItem {
  count: number;
  extras: string;
  extraItems: ExtraOrder[];
  extraIds: string[];
  totalprice: number;
}

export function getComparable(menuItem: Partial<MenuItemOrder>) {
  const { id, extras, multi_ingredients, initial_ingredients, extraItems } = menuItem;
  const copy1 = (<any>Object).assign(
    {},
    {
      id,
      extras: extras ?? '',
      multi_ingredients: multi_ingredients ?? {},
      initial_ingredients: initial_ingredients ?? {},
      extraItems: extraItems ?? [],
    },
  );
  return copy1;
}
