import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConnectedPosition, ConnectionPositionPair, OverlayContainer } from '@angular/cdk/overlay';
import { ViewportScroller } from '@angular/common';
import { Component, HostBinding, HostListener, Inject, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { Provider, SupportedLanguages } from '@bringmos/types';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { AuthService } from '../app/services/auth.service';
import { environment } from '../environments/environment';
import { InfoSectionType } from './modules/info-section/info-section.component';
import { OrderService } from './services/order.service';
import { ProviderService } from './services/provider.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'bringmos-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('accounts', [
      transition(':enter', [
        style({
          transform: 'scale(.9) translateY(-10%)',
          height: '200px',
          opacity: 0,
        }),
        animate(
          '.1s ease-out',
          style({
            transform: 'scale(1) translateY(0%)',
            height: '*',
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ],
})
export class AppComponent {
  @HostBinding('class') public componentCssClass = 'dark-theme';
  // @ViewChild(MatRipple) public ripple: MatRipple;
  public isDarkTheme: Observable<boolean> = of(true);

  language: SupportedLanguages = SupportedLanguages.DE;
  lngs = [
    { code: SupportedLanguages.DE, label: 'Deutsch' },
    { code: SupportedLanguages.IT, label: 'Italiano' },
  ];

  public showProviderContext: boolean = false;
  public positions: ConnectedPosition[] = [
    new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }, 0, 10),
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 10),
  ];

  @ViewChild('drawer', { static: false }) drawer!: MatDrawer;
  public status = '';
  public provider: Provider | undefined = undefined;
  public sliderLabel = 'Website Online';

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  timeForm: UntypedFormGroup;
  public showAccount = false;
  public yoffset = 0;
  public providersLoading = false;
  public providers: Provider[] = [];

  public domain = environment.production ? 'bringmos.com' : 'bringmo.app-junkies.com';
  @HostListener('window:scroll', ['$event']) onScroll(event: Event): void {
    this.yoffset = this.viewPortScroller.getScrollPosition()[1];
  }

  public InfoSectionType: any = InfoSectionType;

  constructor(
    public viewPortScroller: ViewportScroller,
    @Inject('windowObject') public window: Window,
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    public fb: UntypedFormBuilder,
    private translate: TranslateService,
    // private update: UpdateService,
    public orderService: OrderService,
    public overlayContainer: OverlayContainer,
    private themeService: ThemeService,
    public providerService: ProviderService,
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'mdi-clock-start',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/clock-start.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'mdi-clock-end',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/clock-end.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'mdi-plus-minus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/plus-minus.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-1',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-1.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-2.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-3',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-3.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-4',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-4.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-5',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-5.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-6',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-6.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-7',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-7.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'mdi-circle-slice-8',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/circle-slice-8.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'mdi-printer-alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/printer-alert.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'mdi-filter-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/filter-outline.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'mdi_pin_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/pin-outline.svg'),
    );

    this.matIconRegistry.addSvgIcon('mdi_pin', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi/pin.svg'));

    this.translate.setDefaultLang(SupportedLanguages.DE);

    if (localStorage.getItem('language') === 'it') {
      this.language = SupportedLanguages.IT;
      this.translate.use(this.language);
    } else {
      this.language = SupportedLanguages.DE;
      this.translate.use(this.language);
    }

    this.timeForm = this.fb.group({
      delivery_time: ['00:00', [Validators.required]],
    });

    this.auth.provider.subscribe((provider) => {
      if (provider) {
        this.provider = provider;
        if (provider.delivery_time !== this.deliveryTime?.value) {
          this.timeForm.patchValue(this.provider);
        }

        if (this.provider != null) {
          if (provider.store_acceptOrders) {
            this.sliderLabel = 'Website Online';
            this.status = 'online';
          } else {
            this.sliderLabel = 'Website Offline';
            this.status = 'offline';
          }
        }
      }
    });

    this.timeForm.valueChanges.pipe(debounceTime(1000)).subscribe((data) => {
      this.changeDeliveryTime();
    });

    this.isDarkTheme = this.themeService.isDarkTheme;
    this.isDarkTheme.subscribe((thema) => this.onSetTheme(thema ? 'dark-theme' : 'light-theme'));
  }

  public onSetTheme(theme: string): void {
    localStorage.setItem('theme', theme);
    this.overlayContainer.getContainerElement().classList.remove(theme === 'dark-theme' ? 'light-theme' : 'dark-theme');
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }

  public setActiveProvider(provider: Provider): void {
    this.provider = provider;
    this.auth.setActiveProvider(provider);
    window.location.reload();
  }

  closeDrawerHandset() {
    if (this.drawer.mode === 'over') {
      this.drawer.close();
    }
  }

  setLanguage(lng: SupportedLanguages) {
    this.language = lng;
    localStorage.setItem('language', lng);
    this.translate.use(lng);
  }

  changeStatus() {
    if (this.provider) {
      if (this.status === 'offline') {
        const providerChanges = {
          // store_visible: true,
          store_acceptOrders: false,
        };
        this.auth.updateProvider(this.provider, providerChanges);
      } else if (this.status === 'online') {
        const providerChanges = {
          // store_visible: true,
          store_acceptOrders: true,
        };
        this.auth.updateProvider(this.provider, providerChanges);
      }
    }
  }

  changeDeliveryTime() {
    if (this.provider && this.provider.delivery_time !== this.deliveryTime?.value) {
      let providerChanges = {};
      if (!this.deliveryTime?.value) {
        providerChanges = {
          delivery_time: '00:00',
        };
      } else {
        providerChanges = {
          delivery_time: this.deliveryTime?.value,
        };
      }
      this.auth.updateProvider(this.provider, providerChanges);
    }
  }

  public get deliveryTime(): AbstractControl | null {
    return this.timeForm.get('delivery_time');
  }

  public closeAccountCard(): void {
    if (this.showAccount) {
      this.showAccount = false;
    }
  }
}
