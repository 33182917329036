import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Provider } from '@bringmos/types';
import { User } from 'firebase/auth';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'bringmos-accounts-card',
  templateUrl: './accounts-card.component.html',
  styleUrls: ['./accounts-card.component.scss'],
})
export class AccountsCardComponent {
  @Input() public user!: User | undefined;
  @Input() public provider!: Provider | undefined;

  @Output() public closed = new EventEmitter();
  constructor(public authService: AuthService, private router: Router) {}

  public editUserProfile(): void {
    this.router.navigate(['/profile']);
    this.closed.emit();
  }

  public closeCard(element: HTMLElement): void {
    if (!element.classList.contains('dontcloseonclick')) {
      this.closed.emit();
    }
  }

  public lock(): void {
    // TODO call cf to lock the providerscontent
  }

  public logout(): void {
    this.authService.signout();
    this.closed.emit();
  }
}
