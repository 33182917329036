import { Pipe, PipeTransform } from '@angular/core';
import { Category, SupportedCategories } from '@bringmos/types';

@Pipe({
  name: 'category',
})
export class CategoryPipe implements PipeTransform {
  categoryDe: Category[] = SupportedCategories.SUPPORTED_CATEGORIES_DE;
  categoryIt: Category[] = SupportedCategories.SUPPORTED_CATEGORIES_IT;
  categoryEn: Category[] = SupportedCategories.SUPPORTED_CATEGORIES_EN;

  transform(code: string, language?: string): any {
    if (code) {
      let ret: Category | undefined;
      switch (language) {
        case 'de':
          ret = this.categoryDe.find((element) => element.code === code);
          break;
        case 'it':
          ret = this.categoryIt.find((element) => element.code === code);
          break;
        case 'en':
          ret = this.categoryEn.find((element) => element.code === code);
          break;
        default:
          ret = this.categoryDe.find((element) => element.code === code);
      }
      if (ret) {
        return ret.value;
      } else {
        return code;
      }
    }
  }
}
