import { Directive, InjectionToken, Input } from '@angular/core';

let nextUniqueId = 0;

export const BRINGMOS_ERROR = new InjectionToken<ErrorDirective>('BringmosError');

@Directive({
  selector: '[bringmosError]',
  host: {
    class: 'bringmos-error',
    role: 'alert',
    '[attr.id]': 'id',
  },
  providers: [{ provide: BRINGMOS_ERROR, useExisting: ErrorDirective }],
})
export class ErrorDirective {
  @Input() id: string = `bringmos-error-${nextUniqueId++}`;
  constructor() {}
}
