<div class="org-context-card" cdkTrapFocus>
  <div class="spinner-w">
    <mat-spinner diameter="20" *ngIf="providerLoading$ | async" color="accent"> </mat-spinner>
  </div>

  <div class="filter-wrapper">
    <input
      bringmosInput
      class="filter-input"
      [formControl]="filterControl"
      autocomplete="off"
      (click)="$event.stopPropagation()"
      placeholder="{{ 'ORG.PAGES.FILTERPLACEHOLDER' | translate }}"
      #input
    />
  </div>

  <div class="org-wrapper">
    <button
      class="org-button-with-pin"
      mat-button
      [ngClass]="{
        active: pinnedProvider.uid === pinnedProvider?.uid,
        'border-bottom': pinned.selected.length && i === pinned.selected.length - 1
      }"
      [disabled]="!pinnedProvider.uid"
      *ngFor="let pinnedProvider of pinned.selected; index as i"
      (click)="setActiveProvider(pinnedProvider)"
    >
      <div class="org-flex-row">
        <span class="org-span">{{ pinnedProvider.name ? pinnedProvider.name : 'NO NAME' }}</span>
        <template [ngTemplateOutlet]="toggleButton" [ngTemplateOutletContext]="{ key: pinnedProvider }"></template>
      </div>
    </button>
    <ng-container *ngFor="let tempProvider of providers$ | async">
      <button
        *ngIf="!pinned.isSelected(tempProvider)"
        class="org-button-with-pin"
        mat-button
        [ngClass]="{ active: tempProvider.uid === provider?.uid }"
        [disabled]="!tempProvider.uid"
        (click)="setActiveProvider(tempProvider)"
      >
        <div class="org-flex-row">
          <span class="org-span">{{ tempProvider.name ? tempProvider.name : 'NO NAME' }}</span>
          <template [ngTemplateOutlet]="toggleButton" [ngTemplateOutletContext]="{ key: tempProvider }"></template>
        </div>
      </button>
    </ng-container>
  </div>
</div>

<ng-template #toggleButton let-key="key">
  <button
    matTooltip="{{ 'ACTIONS.PIN' | translate }}"
    [ngClass]="{ selected: pinned.isSelected(key) }"
    (click)="toggle(key, $event)"
    class="edit-button"
    mat-icon-button
  >
    <mat-icon *ngIf="pinned.isSelected(key)" svgIcon="mdi_pin"></mat-icon>
    <mat-icon svgIcon="mdi_pin_outline" *ngIf="!pinned.isSelected(key)"></mat-icon>
  </button>
</ng-template>
