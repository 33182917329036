export interface User {
    clientId: string;
    clientToken?: string;
    client_address?: string;
    client_address_extra?: string;
    client_country?: string;
    client_email?: string;
    client_favouriteProviderIds?: string[];
    client_lastOrderId?: string;
    client_name?: string;
    client_phone?: string;
    client_photoUrl?: string;
    client_town?: string;
    client_zip?: number;
    language?: string;
}
