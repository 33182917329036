export class SupportedCategories {
    public static SUPPORTED_CATEGORIES_DE: Category[] = [
        { value: 'Getränke', code: 'drink', avatar: 'drink.png', order: 0 },
        { value: 'Tagesmenü', code: 'aa_dailymenu', avatar: 'maindish.svg', order: 1 },
        { value: 'Starters', code: 'ab_starters', avatar: 'starter.svg', order: 2 },
        { value: 'Kalte Gerichte', code: 'cold', avatar: 'cold.svg', order: 3 },
        { value: 'Bowls', code: 'bowl', avatar: 'bowl.svg', order: 4 },
        { value: 'Salat', code: 'salad', avatar: 'salad.svg', order: 5 },
        { value: 'Hauptspeise', code: 'ac_maindish', avatar: 'maindish.svg', order: 6 },
        { value: 'Spezialitäten', code: 'special', avatar: 'special.svg', order: 7 },
        { value: 'Pizza', code: 'pizza', avatar: 'pizza.svg', order: 8 },
        { value: 'Family pizza', code: 'pizzamammut', avatar: 'mammut.png', order: 9 },
        { value: 'Pizza Filoncino', code: 'pizzafiloncino', avatar: 'pizzafiloncino.svg', order: 10 },
        { value: 'Pizza Calzone', code: 'pizzacalzone', avatar: 'pizzacalzone.svg', order: 11 },
        { value: 'Burger', code: 'burger', avatar: 'burger.svg', order: 12 },
        { value: 'Fleisch', code: 'meat', avatar: 'meat.svg', order: 13 },
        { value: 'BBQ & Grill', code: 'bbq', avatar: 'bbq.svg', order: 14 },
        { value: 'Hähnchen', code: 'chicken', avatar: 'chicken.svg', order: 15 },
        { value: 'Fast Food', code: 'fastfood', avatar: 'fastfood.svg', order: 16 },
        { value: 'Finger Food', code: 'fingerfood', avatar: 'fingerfood.svg', order: 17 },
        { value: 'Fisch', code: 'fish', avatar: 'fish.svg', order: 18 },
        { value: 'Kebap', code: 'kebap', avatar: 'kebap.svg', order: 19 },
        { value: 'Beilagen', code: 'ad_sidedish', avatar: 'beilage.svg', order: 20 },
        { value: 'Snacks', code: 'snacks', avatar: 'snacks.svg', order: 21 },
        { value: 'Wraps', code: 'wraps', avatar: 'wraps.svg', order: 22 },
        { value: 'Südtirolerisch', code: 'southtyrol', avatar: 'southtyrol.svg', order: 23 },
        { value: 'Italienisch', code: 'italian', avatar: 'italian.svg', order: 24 },
        { value: 'Mexikanisch', code: 'mexican', avatar: 'mexican.svg', order: 25 },
        { value: 'Spanisch / Tappas', code: 'spanish', avatar: 'spanish.svg', order: 26 },
        { value: 'Asiatisch', code: 'asian', avatar: 'asian.svg', order: 27 },
        { value: 'Vegetarisch', code: 'veggy', avatar: 'veggy.svg', order: 28 },
        { value: 'Nudeln', code: 'pasta', avatar: 'pasta.svg', order: 29 },
        { value: 'Aus der Küche', code: 'kitchen', avatar: 'kitchen.svg', order: 30 },
        { value: 'Gemüsegerichte', code: 'vegetable', avatar: 'vegetable.svg', order: 31 },
        { value: 'Für Kinder', code: 'kids', avatar: 'kids.svg', order: 32 },
        { value: 'Meeresfrüchte', code: 'seafood', avatar: 'seafood.svg', order: 33 },
        { value: 'Suppe', code: 'soup', avatar: 'soup.svg', order: 34 },
        { value: 'Frühstück', code: 'breakfast', avatar: 'breakfast.svg', order: 35 },
        { value: 'Brunch', code: 'brunch', avatar: 'brunch.svg', order: 36 },
        { value: 'Kuchen', code: 'cake', avatar: 'cake.svg', order: 37 },
        { value: 'Gebackenes', code: 'bakery', avatar: 'bakery.svg', order: 38 },
        { value: 'Dessert', code: 'dessert', avatar: 'dessert.svg', order: 39 },
        { value: 'Kekse', code: 'cookies', avatar: 'cookies.svg', order: 40 },
        { value: 'Anderes', code: 'other', avatar: 'other.svg', order: 41 },

        // { value: 'Getränke', code: 'grocery_drink', avatar: 'grocery_drink.png' },
        // { value: 'Backwaren', code: 'grocery_bread', avatar: 'grocery_bread.png' },
        // { value: 'Molkereiprodukte und Eier', code: 'grocery_milk', avatar: 'grocery_milk.png' },
        // { value: 'Grundnahrungsmittel', code: 'grocery_basics', avatar: 'grocery_basics.png' },
        // { value: 'Essig - Öle - Gewürze', code: 'grocery_oil', avatar: 'grocery_oil.png' },
        // { value: 'Konserven und Sugo', code: 'grocery_conserves', avatar: 'grocery_conserves.png' },
        // { value: 'Mehl und Getreide', code: 'grocery_flour', avatar: 'grocery_flour.png' },
        // { value: 'Tiefkühlprodukte', code: 'grocery_frozen', avatar: 'grocery_frozen.png' },
        // { value: 'Obst und Gemüse', code: 'grocery_fruit', avatar: 'grocery_fruit.png' },
        // { value: 'Wurst und Fleischwaren', code: 'grocery_meat', avatar: 'grocery_meat.png' },
        // { value: 'Tee - Kaffee - Kakao', code: 'grocery_tea', avatar: 'grocery_tea.png' },
        // { value: 'Süßes und Snacks', code: 'grocery_snack', avatar: 'grocery_snack.png' },
        // { value: 'Aufstriche', code: 'grocery_marmelade', avatar: 'grocery_marmelade.png' },
        // { value: 'Müsli und Cornflakes', code: 'grocery_muesli', avatar: 'grocery_muesli.png' },
        // { value: 'Zum Backen', code: 'grocery_bakery', avatar: 'grocery_bakery.png' },
        // { value: 'Zeitungen', code: 'grocery_newspaper', avatar: 'grocery_newspaper.png' },
        // { value: 'Tabakwaren', code: 'grocery_tobacco', avatar: 'grocery_tobacco.png' },
        // { value: 'Putz und Waschmittel', code: 'grocery_cleaning', avatar: 'grocery_cleaning.png' },
        // { value: 'Hygiene und Kosmetik', code: 'grocery_hygien', avatar: 'grocery_hygien.png' },
        // { value: 'Tiernahrung', code: 'grocery_animalfood', avatar: 'grocery_animalfood.png' },
        // { value: 'Fisch', code: 'grocery_fish', avatar: 'grocery_fish.png' },
        // { value: 'Fertigprodukte und Soßen', code: 'grocery_sauce', avatar: 'grocery_sauce.png' },
        // { value: 'Sonstiges', code: 'grocery_other', avatar: 'grocery_other.png' },
    ];

    public static SUPPORTED_CATEGORIES_IT: Category[] = [
        { value: 'Bibite', code: 'drink', avatar: 'drink.png', order: 0 },
        { value: 'Menu giornaliero', code: 'aa_dailymenu', avatar: 'maindish.svg', order: 1 },
        { value: 'Antipasti', code: 'ab_starters', avatar: 'starter.svg', order: 2 },
        { value: 'Piatti freddi', code: 'cold', avatar: 'cold.svg', order: 3 },
        { value: 'Bowls', code: 'bowl', avatar: 'bowl.svg', order: 4 },
        { value: 'Insalata', code: 'salad', avatar: 'salad.svg', order: 5 },
        { value: 'Piatti principali', code: 'ac_maindish', avatar: 'maindish.svg', order: 6 },
        { value: 'Specialità', code: 'special', avatar: 'special.svg', order: 7 },
        { value: 'Pizza', code: 'pizza', avatar: 'pizza.svg', order: 8 },
        { value: 'Pizza famigliare', code: 'pizzamammut', avatar: 'mammut.png', order: 9 },
        { value: 'Pizza Filoncino', code: 'pizzafiloncino', avatar: 'pizzafiloncino.svg', order: 10 },
        { value: 'Pizza Calzone', code: 'pizzacalzone', avatar: 'pizzacalzone.svg', order: 11 },
        { value: 'Burger', code: 'burger', avatar: 'burger.svg', order: 12 },
        { value: 'Carne', code: 'meat', avatar: 'meat.svg', order: 13 },
        { value: 'BBQ & Griglia', code: 'bbq', avatar: 'bbq.svg', order: 14 },
        { value: 'Pollo', code: 'chicken', avatar: 'chicken.svg', order: 15 },
        { value: 'Fast Food', code: 'fastfood', avatar: 'fastfood.svg', order: 16 },
        { value: 'Finger Food', code: 'fingerfood', avatar: 'fingerfood.svg', order: 17 },
        { value: 'Pesce', code: 'fish', avatar: 'fish.svg', order: 18 },
        { value: 'Kebap', code: 'kebap', avatar: 'kebap.svg', order: 19 },
        { value: 'Contorni', code: 'ad_sidedish', avatar: 'beilage.svg', order: 20 },
        { value: 'Snacks', code: 'snacks', avatar: 'snacks.svg', order: 21 },
        { value: 'Wraps', code: 'wraps', avatar: 'wraps.svg', order: 22 },
        { value: 'Sudtirolese', code: 'southtyrol', avatar: 'southtyrol.svg', order: 23 },
        { value: 'Italiano', code: 'italian', avatar: 'italian.svg', order: 24 },
        { value: 'Messicano', code: 'mexican', avatar: 'mexican.svg', order: 25 },
        { value: 'Spagnolo / Tapas', code: 'spanish', avatar: 'spanish.svg', order: 26 },
        { value: 'Asiatico', code: 'asian', avatar: 'asian.svg', order: 27 },
        { value: 'Vegetariano', code: 'veggy', avatar: 'veggy.svg', order: 28 },
        { value: 'Pasta', code: 'pasta', avatar: 'pasta.svg', order: 29 },
        { value: 'Dalla cucina', code: 'kitchen', avatar: 'kitchen.svg', order: 30 },
        { value: 'Piatti di verdura', code: 'vegetable', avatar: 'vegetable.svg', order: 31 },
        { value: 'Per bambini', code: 'kids', avatar: 'kids.svg', order: 32 },
        { value: 'Frutti di mare', code: 'seafood', avatar: 'seafood.svg', order: 33 },
        { value: 'Zuppe', code: 'soup', avatar: 'soup.svg', order: 34 },
        { value: 'Colazione', code: 'breakfast', avatar: 'breakfast.svg', order: 35 },
        { value: 'Brunch', code: 'brunch', avatar: 'brunch.svg', order: 36 },
        { value: 'Torte', code: 'cake', avatar: 'cake.svg', order: 37 },
        { value: 'Cotto al forno', code: 'bakery', avatar: 'bakery.svg', order: 38 },
        { value: 'Biscotti', code: 'cookies', avatar: 'cookies.svg', order: 39 },
        { value: 'Dessert', code: 'dessert', avatar: 'dessert.svg', order: 40 },
        { value: 'Altro', code: 'other', avatar: 'other.svg', order: 41 },

        // { value: 'Bibite', code: 'grocery_drink', avatar: 'grocery_drink.png' },
        // { value: 'Prodotti da forno', code: 'grocery_bread', avatar: 'grocery_bread.png' },
        // { value: 'Latticini e Uova', code: 'grocery_milk', avatar: 'grocery_milk.png' },
        // { value: 'Pasta e Riso', code: 'grocery_basics', avatar: 'grocery_basics.png' },
        // { value: 'Olio - Aceto - Spezie', code: 'grocery_oil', avatar: 'grocery_oil.png' },
        // { value: 'Cibo in scatola e Sugo', code: 'grocery_conserves', avatar: 'grocery_conserves.png' },
        // { value: 'Farina e Cereali', code: 'grocery_flour', avatar: 'grocery_flour.png' },
        // { value: 'Prodotti surgelati', code: 'grocery_frozen', avatar: 'grocery_frozen.png' },
        // { value: 'Frutta e Verdura', code: 'grocery_fruit', avatar: 'grocery_fruit.png' },
        // { value: 'Carne e Salsiccia', code: 'grocery_meat', avatar: 'grocery_meat.png' },
        // { value: 'Tè - Caffè - Cacao', code: 'grocery_tea', avatar: 'grocery_tea.png' },
        // { value: 'Caramelle e Snack,', code: 'grocery_snack', avatar: 'grocery_snack.png' },
        // { value: 'Diffusione', code: 'grocery_marmelade', avatar: 'grocery_marmelade.png' },
        // { value: 'Muesli e Cornflakes', code: 'grocery_muesli', avatar: 'grocery_muesli.png' },
        // { value: 'Per la cottura al forno', code: 'grocery_bakery', avatar: 'grocery_bakery.png' },
        // { value: 'Giornali', code: 'grocery_newspaper', avatar: 'grocery_newspaper.png' },
        // { value: 'Prodotti del tabacco', code: 'grocery_tobacco', avatar: 'grocery_tobacco.png' },
        // { value: 'Gesso e detersivo', code: 'grocery_cleaning', avatar: 'grocery_cleaning.png' },
        // { value: 'Igiene e Cosmetici', code: 'grocery_hygien', avatar: 'grocery_hygien.png' },
        // { value: 'Cibo per animali domestici', code: 'grocery_animalfood', avatar: 'grocery_animalfood.png' },
        // { value: 'Pesce', code: 'grocery_fish', avatar: 'grocery_fish.png' },
        // { value: 'Prodotti finiti e Salse', code: 'grocery_sauce', avatar: 'grocery_sauce.png' },
        // { value: 'Altro', code: 'grocery_other', avatar: 'grocery_other.png' },
    ];

    public static SUPPORTED_CATEGORIES_EN: Category[] = [
        { value: 'Drinks', code: 'drink', avatar: 'drink.png', order: 0 },
        { value: 'Daily Menu', code: 'aa_dailymenu', avatar: 'maindish.svg', order: 1 },
        { value: 'Starters', code: 'ab_starters', avatar: 'starter.svg', order: 2 },
        { value: 'Cold cuisine', code: 'cold', avatar: 'cold.svg', order: 3 },
        { value: 'Bowls', code: 'bowls', avatar: 'bowls.svg', order: 4 },
        { value: 'Salad', code: 'salad', avatar: 'salad.svg', order: 5 },
        { value: 'Main dish', code: 'ac_maindish', avatar: 'maindish.svg', order: 6 },
        { value: 'Specialties', code: 'special', avatar: 'special.svg', order: 7 },
        { value: 'Pizza', code: 'pizza', avatar: 'pizza.svg', order: 8 },
        { value: 'Familienpizza', code: 'pizzamammut', avatar: 'mammut.png', order: 9 },
        { value: 'Pizza Filoncino', code: 'pizzafiloncino', avatar: 'pizzafiloncino.svg', order: 10 },
        { value: 'Pizza Calzone', code: 'pizzacalzone', avatar: 'pizzacalzone.svg', order: 11 },
        { value: 'Burger', code: 'burger', avatar: 'burger.svg', order: 12 },
        { value: 'Meat', code: 'meat', avatar: 'meat.svg', order: 13 },
        { value: 'BBQ & Grill', code: 'bbq', avatar: 'bbq.svg', order: 14 },
        { value: 'Chicken', code: 'chicken', avatar: 'chicken.svg', order: 15 },
        { value: 'Fast Food', code: 'fastfood', avatar: 'fastfood.svg', order: 16 },
        { value: 'Finger Food', code: 'fingerfood', avatar: 'fingerfood.svg', order: 17 },
        { value: 'Fish', code: 'fish', avatar: 'fish.svg', order: 18 },
        { value: 'Kebap', code: 'kebap', avatar: 'kebap.svg', order: 19 },
        { value: 'Side dishes', code: 'ad_sidedish', avatar: 'beilage.svg', order: 20 },
        { value: 'Snacks', code: 'snacks', avatar: 'snacks.svg', order: 21 },
        { value: 'Wraps', code: 'wraps', avatar: 'wraps.svg', order: 22 },
        { value: 'Southtyrolean', code: 'southtyrol', avatar: 'southtyrol.svg', order: 23 },
        { value: 'Italian', code: 'italian', avatar: 'italian.svg', order: 24 },
        { value: 'Mexican', code: 'mexican', avatar: 'mexican.svg', order: 25 },
        { value: 'Spanish / Tappas', code: 'spanish', avatar: 'spanish.svg', order: 26 },
        { value: 'Asian', code: 'asian', avatar: 'asian.svg', order: 27 },
        { value: 'Veggy', code: 'veggy', avatar: 'veggy.svg', order: 28 },
        { value: 'Pasta', code: 'pasta', avatar: 'pasta.svg', order: 29 },
        { value: 'Kitchen dishes', code: 'kitchen', avatar: 'kitchen.svg', order: 30 },
        { value: 'Vegetable dishes', code: 'vegetable', avatar: 'vegetable.svg', order: 31 },
        { value: 'For kids', code: 'kids', avatar: 'kids.svg', order: 32 },
        { value: 'Seafood', code: 'seafood', avatar: 'seafood.svg', order: 33 },
        { value: 'Soup', code: 'soup', avatar: 'soup.svg', order: 34 },
        { value: 'Breakfast', code: 'breakfast', avatar: 'breakfast.svg', order: 35 },
        { value: 'Brunch', code: 'brunch', avatar: 'brunch.svg', order: 36 },
        { value: 'Cake', code: 'cake', avatar: 'cake.svg', order: 37 },
        { value: 'Baked', code: 'bakery', avatar: 'bakery.svg', order: 38 },
        { value: 'Dessert', code: 'dessert', avatar: 'dessert.svg', order: 39 },
        { value: 'Biscotti', code: 'cookies', avatar: 'cookies.svg', order: 40 },
        { value: 'Other', code: 'other', avatar: 'other.svg', order: 41 },

        // { value: 'Drinks', code: 'grocery_drink', avatar: 'grocery_drink.png' },
        // { value: 'Baked goods', code: 'grocery_bread', avatar: 'grocery_bread.png' },
        // { value: 'Dairy products and eggs', code: 'grocery_milk', avatar: 'grocery_milk.png' },
        // { value: 'Basic food', code: 'grocery_basics', avatar: 'grocery_basics.png' },
        // { value: 'Oil - Vinegar - Spices', code: 'grocery_oil', avatar: 'grocery_oil.png' },
        // { value: 'Canned food and Sugo', code: 'grocery_conserves', avatar: 'grocery_conserves.png' },
        // { value: 'Flour and Corn (Cereals)', code: 'grocery_flour', avatar: 'grocery_flour.png' },
        // { value: 'Frozen food products', code: 'grocery_frozen', avatar: 'grocery_frozen.png' },
        // { value: 'Fruit and Vegetables', code: 'grocery_fruit', avatar: 'grocery_fruit.png' },
        // { value: 'Meat and Sausages', code: 'grocery_meat', avatar: 'grocery_meat.png' },
        // { value: 'Tea - Coffee - Cocoa', code: 'grocery_tea', avatar: 'grocery_tea.png' },
        // { value: 'Candy and Snacks,', code: 'grocery_snack', avatar: 'grocery_snack.png' },
        // { value: 'Spreads', code: 'grocery_marmelade', avatar: 'grocery_marmelade.png' },
        // { value: 'Cereals and Cornflakes', code: 'grocery_muesli', avatar: 'grocery_muesli.png' },
        // { value: 'For baking', code: 'grocery_bakery', avatar: 'grocery_bakery.png' },
        // { value: 'Newspapers', code: 'grocery_newspaper', avatar: 'grocery_newspaper.png' },
        // { value: 'Tobacco products', code: 'grocery_tobacco', avatar: 'grocery_tobacco.png' },
        // { value: 'Plaster and Detergent', code: 'grocery_cleaning', avatar: 'grocery_cleaning.png' },
        // { value: 'Hygiene and Cosmetics', code: 'grocery_hygien', avatar: 'grocery_hygien.png' },
        // { value: 'Pet food', code: 'grocery_animalfood', avatar: 'grocery_animalfood.png' },
        // { value: 'Fish', code: 'grocery_fish', avatar: 'grocery_fish.png' },
        // { value: 'Finished products and sauces', code: 'grocery_sauce', avatar: 'grocery_sauce.png' },
        // { value: 'Miscellaneous', code: 'grocery_other', avatar: 'grocery_other.png' },

    ];
}

export interface Category {
    value: string;
    code: string;
    avatar: string;
    order: number;
}
