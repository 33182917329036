import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ThemeService {
  private _darkTheme: Subject<boolean> = new Subject<boolean>();
  public isDarkTheme: Observable<boolean> = this._darkTheme.asObservable();

  constructor() {
    const theme = localStorage.getItem('theme');
    if (theme) {
      if (theme === 'light-theme') {
        this.setDarkTheme(false);
        console.log(false);
      } else {
        this.setDarkTheme(true);
        console.log(true);
      }
    }
  }

  setDarkTheme(isDarkTheme: boolean): void {
    this._darkTheme.next(isDarkTheme);
  }
}
