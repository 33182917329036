import { Component } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';

function passwordConfirmValidator(c: AbstractControl): any {
  if (!c.parent || !c) {
    return;
  }
  const pwd = c.parent.get('newPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) {
    return;
  }
  if (pwd.value !== cpwd.value) {
    return { invalid: true, notequal: 'Password is not equal' };
  }
}

@Component({
  selector: 'bringmos-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent {
  public passwordForm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private snack: MatSnackBar,
    public auth: Auth,
    private translate: TranslateService,
  ) {
    const validators: Validators[] = [Validators.required];
    this.passwordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', validators],
      confirmPassword: ['', [...validators, passwordConfirmValidator]],
    });
  }

  public setPassword(): void {
    const user = this.auth.currentUser;
    if (user && user.email && this.oldPassword?.value) {
      const credentials = EmailAuthProvider.credential(user.email, this.oldPassword.value);

      reauthenticateWithCredential(user, credentials).then(
        (success) => {
          if (this.newPassword?.value) {
            updatePassword(user, this.newPassword.value)
              .then(() => {
                this.translate.get('pwd.setsuccess').subscribe((pwdSet) => {
                  this.snack.open(pwdSet, '', { duration: 3000 });
                });
              })
              .catch((error) => {
                this.translate.get('pwd.seterror').subscribe((pwdSet) => {
                  this.snack.open(pwdSet, '', { duration: 3000 });
                });
              });
          }
        },
        (error) => {
          if (error.code === 'auth/wrong-password') {
            this.translate.get('pwd.wrongpassword').subscribe((wrongPwd) => {
              this.snack.open(wrongPwd, '', { duration: 3000 });
            });
          }
        },
      );
    }
  }

  public get oldPassword(): AbstractControl | null {
    return this.passwordForm.get('oldPassword');
  }

  public get newPassword(): AbstractControl | null {
    return this.passwordForm.get('newPassword');
  }

  public get confirmPassword(): AbstractControl | null {
    return this.passwordForm.get('confirmPassword');
  }
}
