<div class="password-wrapper max-width-container">
  <h1>{{ 'pwd.title' | translate }}</h1>
  <p class="desc bringmos-secondary-text">{{ 'pwd.desc' | translate }}</p>

  <form *ngIf="passwordForm && auth.currentUser" [formGroup]="passwordForm" (ngSubmit)="setPassword()">
    <input type="hidden" [value]="auth.currentUser.email" autocomplete="username" name="username" />

    <div class="password-content">
      <bringmos-form-field class="formfield">
        <bringmos-label>{{ 'pwd.old' | translate }}</bringmos-label>
        <input
          autocomplete="current-password"
          name="oldPassword"
          bringmosInput
          formControlName="oldPassword"
          type="password"
        />

        <span bringmosError *ngIf="oldPassword && oldPassword.errors && oldPassword.errors['required']">
          {{ 'pwd.required' | translate }}
        </span>
      </bringmos-form-field>
      <bringmos-form-field class="formfield">
        <bringmos-label>{{ 'pwd.new' | translate }}</bringmos-label>
        <input autocomplete="new-password" name="newPassword" bringmosInput formControlName="newPassword" type="password" />

        <span bringmosError *ngIf="newPassword && newPassword.errors && newPassword.errors['required']">
          {{ 'pwd.required' | translate }}
        </span>
      </bringmos-form-field>
      <bringmos-form-field class="formfield">
        <bringmos-label>{{ 'pwd.confirm' | translate }}</bringmos-label>
        <input
          autocomplete="new-password"
          name="passwordRepeat"
          bringmosInput
          formControlName="confirmPassword"
          type="password"
        />
        <span bringmosError *ngIf="confirmPassword && confirmPassword.errors && confirmPassword.errors['notequal']">
          {{ 'pwd.notequal' | translate }}
        </span>
      </bringmos-form-field>
    </div>
    <div class="btn-container">
      <button class="submit-button" [disabled]="passwordForm.invalid" mat-raised-button color="primary">
        {{ 'pwd.set' | translate }}
      </button>
    </div>
  </form>
</div>
