import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  InjectionToken,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { mixinColor } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { cnslFormFieldAnimations } from './animations';
import { BRINGMOS_ERROR, ErrorDirective } from './error.directive';

export const BRINGMOS_FORM_FIELD = new InjectionToken<FormFieldComponent>('CnslFormFieldComponent');

const _FormFieldBase = mixinColor(
  class {
    constructor(public _elementRef: ElementRef) {}
  },
  'primary',
);

@Component({
  selector: 'bringmos-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  providers: [{ provide: BRINGMOS_FORM_FIELD, useExisting: FormFieldComponent }],
  host: {
    '[class.ng-untouched]': '_shouldForward("untouched")',
    '[class.ng-touched]': '_shouldForward("touched")',
    '[class.ng-pristine]': '_shouldForward("pristine")',
    '[class.ng-dirty]': '_shouldForward("dirty")',
    '[class.ng-valid]': '_shouldForward("valid")',
    '[class.ng-invalid]': '_shouldForward("invalid")',
    '[class.ng-pending]': '_shouldForward("pending")',
    '[class.bringmos-form-field-disabled]': '_control.disabled',
    '[class.bringmos-form-field-autofilled]': '_control.autofilled',
    '[class.bringmos-focused]': '_control.focused',
    '[class.bringmos-form-field-invalid]': '_control.errorState',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [cnslFormFieldAnimations.transitionMessages],
})
export class FormFieldComponent extends _FormFieldBase implements OnDestroy, AfterContentInit, AfterViewInit {
  focused: boolean = false;
  private _destroyed: Subject<void> = new Subject<void>();

  @ViewChild('connectionContainer', { static: true })
  _connectionContainerRef!: ElementRef;

  @ViewChild('inputContainer') _inputContainerRef!: ElementRef;

  @ContentChild(MatFormFieldControl)
  _controlNonStatic!: MatFormFieldControl<any>;

  @ContentChild(MatFormFieldControl, { static: true })
  _controlStatic!: MatFormFieldControl<any>;

  get _control(): MatFormFieldControl<any> {
    return this._explicitFormFieldControl || this._controlNonStatic || this._controlStatic;
  }

  set _control(value: MatFormFieldControl<any>) {
    this._explicitFormFieldControl = value;
  }

  private _explicitFormFieldControl!: MatFormFieldControl<any>;

  _subscriptAnimationState: string = '';

  @ContentChildren(BRINGMOS_ERROR as any, { descendants: true })
  _errorChildren!: QueryList<ErrorDirective>;

  constructor(public elementRef: ElementRef, private _changeDetectorRef: ChangeDetectorRef) {
    super(elementRef);
  }

  public ngAfterViewInit(): void {
    // Avoid animations on load.
    this._subscriptAnimationState = 'enter';
    this._changeDetectorRef.detectChanges();

    const control = this._control;

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.pipe(startWith(null)).subscribe(() => {
      // this._validatePlaceholders();
      this._syncDescribedByIds();
      this._changeDetectorRef.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public ngAfterContentInit(): void {
    this._validateControlChild();

    const control = this._control;

    if (control.controlType) {
      this._elementRef.nativeElement.classList.add(`bringmos-form-field-type-${control.controlType}`);
    }

    control.stateChanges.pipe(startWith(null)).subscribe(() => {
      this._syncDescribedByIds();
      this._changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => this._changeDetectorRef.markForCheck());
    }

    // Update the aria-described by when the number of errors changes.
    this._errorChildren.changes.pipe(startWith(null)).subscribe(() => {
      this._syncDescribedByIds();
      this._changeDetectorRef.markForCheck();
    });
  }

  /** Throws an error if the form field's control is missing. */
  protected _validateControlChild(): void {
    if (!this._control) {
      throw Error('bringmos-form-field must contain a MatFormFieldControl.');
    }
  }

  private _syncDescribedByIds(): void {
    if (this._control) {
      const ids: string[] = [];

      if (this._control.userAriaDescribedBy && typeof this._control.userAriaDescribedBy === 'string') {
        ids.push(...this._control.userAriaDescribedBy.split(' '));
      }

      if (this._errorChildren) {
        ids.push(...this._errorChildren.map((error) => error.id));
      }

      this._control.setDescribedByIds(ids);
    }
  }

  /** Determines whether a class from the NgControl should be forwarded to the host element. */
  _shouldForward(prop: keyof NgControl): boolean {
    const ngControl: any = this._control ? this._control.ngControl : null;
    return ngControl && ngControl[prop];
  }

  /** Determines whether to display hints or errors. */
  _getDisplayedMessages(): 'error' | 'hint' {
    return this._errorChildren && this._errorChildren.length > 0 ? 'error' : 'hint';
  }
}
