import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem, SupportedLanguages } from '@bringmos/types';

import { DifferenceType, OrderHelperService } from '../services/order-helper.service';

@Pipe({
  name: 'ingredientsadded',
})
export class IngredientsAddedPipe implements PipeTransform {
  constructor(private helper: OrderHelperService) {}
  transform(menuItem: MenuItem, language: SupportedLanguages): unknown {
    return this.helper.getIngredientsDifferenceString(menuItem, language, DifferenceType.ADDED);
  }
}
