import { Timestamp } from 'firebase/firestore';

export interface Rating {
  clientId: string;
  client_name: string;
  client_photoUrl?: string;
  comment: string;
  date: Timestamp;
  rating_overall: number;
}
