import { Component, Input } from '@angular/core';

export enum InfoSectionType {
  INFO = 'INFO',
  WARN = 'WARN',
  ALERT = 'ALERT',
}

@Component({
  selector: 'bringmos-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss'],
})
export class InfoSectionComponent {
  @Input() type = InfoSectionType.INFO;
}
