import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { OutsideClickModule } from '../../directives/outside-click/outside-click.module';
import { AvatarModule } from '../avatar/avatar.module';
import { AccountsCardComponent } from './accounts-card.component';

@NgModule({
  declarations: [AccountsCardComponent],
  imports: [
    CommonModule,
    AvatarModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatProgressBarModule,
    OutsideClickModule,
    TranslateModule,
  ],
  exports: [AccountsCardComponent],
})
export class AccountsCardModule {}
