<div class="acc_card card" bringmosOutsideClick *ngIf="user" (clickOutside)="closeCard($event)">
  <img
    *ngIf="provider && user.uid === provider.uid && provider.picURL; else defaultAvatar"
    class="avatar"
    [src]="provider.picURL"
  />
  <ng-template #defaultAvatar>
    <bringmos-avatar [size]="80" [name]="user.displayName ?? ''" [forColor]="user.email ?? ''"></bringmos-avatar>
  </ng-template>

  <span class="u-name" *ngIf="provider && user.uid === provider?.uid; else username">{{ provider.name }}</span>
  <ng-template #username>
    <span class="u-name">{{ user.displayName }}</span>
  </ng-template>

  <span class="u-email">{{ user.email }}</span>

  <button
    *ngIf="!authService.admin && provider && provider.verified"
    (click)="editUserProfile()"
    color="primary"
    mat-stroked-button
  >
    {{ 'sidenav.editprofile' | translate }}
  </button>

  <div class="l-accounts">
    <a class="l-row" href="mailto:info@bringmos.com">
      <div class="icon-wrapper">
        <i class="las la-info"></i>
      </div>
      <span class="l-col">
        <span class="l-title">{{ 'sidenav.help' | translate }}</span>
      </span>
      <span class="fill-space"></span>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </a>
    <a class="l-row" [routerLink]="['/change-password']">
      <div class="icon-wrapper">
        <i class="las la-key"></i>
      </div>
      <span class="l-col">
        <span class="l-title">{{ 'sidenav.chpassword' | translate }}</span>
      </span>
      <span class="fill-space"></span>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </a>
  </div>

  <button color="warn" (click)="logout()" mat-stroked-button>
    {{ 'sidenav.logout' | translate }}
  </button>
</div>
