import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OutsideClickModule } from './directives/outside-click/outside-click.module';
import { AccountsCardModule } from './modules/accounts-card/accounts-card.module';
import { AvatarModule } from './modules/avatar/avatar.module';
import { CardModule } from './modules/card/card.module';
import { FormFieldModule } from './modules/form-field/form-field.module';
import { InfoSectionModule } from './modules/info-section/info-section.module';
import { InputModule } from './modules/input/input.module';
import { PasswordComponent } from './modules/password/password.component';
import { PipesModule } from './modules/pipes/pipes.module';
import { ProviderContextModule } from './modules/provider-context/provider-context.module';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { AdminGuard } from './services/admin.guard';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { OrderService } from './services/order.service';
import { PrintService } from './services/print.service';
import { ThemeService } from './services/theme.service';
import { VerifiedGuard } from './services/verified.guard';

registerLocaleData(localeDe);
registerLocaleData(localeIt);

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [AppComponent, PasswordComponent, OrderStatusPipe],
  imports: [
    CommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions(undefined, 'europe-west1')),
    FormsModule,
    AvatarModule,
    BrowserModule,
    MatCheckboxModule,
    AppRoutingModule,
    // QuicklinkModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    // }),
    BrowserAnimationsModule,
    ProviderContextModule,
    OverlayModule,
    MatRippleModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    InputModule,
    MatTooltipModule,
    MatMenuModule,
    FormFieldModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    AccountsCardModule,
    CardModule,
    InfoSectionModule,
    PipesModule,
    OutsideClickModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  providers: [
    AuthGuard,
    AdminGuard,
    VerifiedGuard,
    AuthService,
    ThemeService,
    PrintService,
    OrderService,
    { provide: 'windowObject', useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
