import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Provider } from '@bringmos/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, finalize, map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'bringmos-provider-context',
  templateUrl: './provider-context.component.html',
  styleUrls: ['./provider-context.component.scss'],
})
export class ProviderContextComponent implements OnInit {
  public pinned: SelectionModel<Provider> = new SelectionModel<Provider>(true, []);

  public providerLoading$: BehaviorSubject<any> = new BehaviorSubject(false);
  public providers$: Observable<Provider[]> = of([]);
  public filterControl: FormControl<string | null> = new FormControl<string | null>('');
  @Input() public provider?: Provider;
  @ViewChild('input', { static: false }) input!: ElementRef;
  @Output() public closedCard: EventEmitter<void> = new EventEmitter();
  @Output() public setProvider: EventEmitter<Provider> = new EventEmitter();

  constructor(public auth: AuthService) {
    this.filterControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.loadProviders((value ?? '').trim().toLowerCase());
    });
  }

  public ngOnInit(): void {
    this.focusFilter();
    this.loadProviders();
  }

  public setActiveProvider(provider: Provider) {
    this.setProvider.emit(provider);
    this.closedCard.emit();
  }

  public loadProviders(filter?: string): void {
    if (!filter) {
      const value = this.input?.nativeElement?.value;
      if (value) {
        filter = value;
      }
    }

    (this.providers$ = this.auth.providers.pipe(
      map((providers: Provider[]) => {
        return providers
          .filter((p) => {
            if (filter) {
              return p.name?.toLowerCase().includes(filter);
            } else {
              return true;
            }
          })
          .sort((left, right) => {
            if (left && right && left.name && right.name) {
              return left.name.localeCompare(right.name);
            } else {
              return -1;
            }
          });
      }),
      tap((providers: Provider[]) => {
        this.pinned.clear();
        this.getPrefixedItem('pinned-providers').then((stringifiedProviders) => {
          if (stringifiedProviders) {
            const orgIds: string[] = JSON.parse(stringifiedProviders);
            const pinnedProviders = providers.filter((p) => orgIds.includes(p.uid));
            pinnedProviders.forEach((p) => this.pinned.select(p));
          }
        });
      }),
    )),
      finalize(() => {
        this.providerLoading$.next(false);
      });
  }

  public closeCard(element: HTMLElement): void {
    if (!element.classList.contains('dontcloseonclick') && !element.classList.contains('mat-button-wrapper')) {
      this.closedCard.emit();
    }
  }

  private focusFilter(): void {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  public toggle(item: Provider, event: any): void {
    event.stopPropagation();
    this.pinned.toggle(item);

    this.setPrefixedItem('pinned-providers', JSON.stringify(this.pinned.selected.map((o) => o.uid)));
  }

  private async getPrefixedItem(key: string): Promise<string | null> {
    return localStorage.getItem(`${key}`);
  }

  private async setPrefixedItem(key: string, value: any): Promise<void> {
    return localStorage.setItem(`${key}`, value);
  }
}
