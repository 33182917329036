import { Injectable } from '@angular/core';
import { Auth, authState, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { collectionChanges, doc, docSnapshots, Firestore, setDoc } from '@angular/fire/firestore';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import {
  LanguageMap,
  Link,
  Provider,
  ProviderMenuPreferences,
  ProviderOrderPreferences,
  SupportedLanguages,
} from '@bringmos/types';
import { stringLength } from '@firebase/util';
import { TranslateService } from '@ngx-translate/core';
import { getIdTokenResult, User, UserCredential } from 'firebase/auth';
import { collection, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  public authState$: Observable<User | null>;
  public provider: Observable<Provider | undefined>;
  public providers: Observable<Provider[]> = of([]);

  link: Link = {
    uid: '',
  };
  public admin: boolean = false;
  public tenant: string = '';
  public availableProviderIds: string[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private auth: Auth,
    private firestore: Firestore,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.authState$ = authState(this.auth);

    this.provider = this.authState$.pipe(
      switchMap((fireuser) => {
        if (fireuser) {
          return from(getIdTokenResult(fireuser)).pipe(
            switchMap((idTokenResult) => {
              if (idTokenResult && idTokenResult.claims['tenant']) {
                const tenant = idTokenResult.claims['tenant'] as string;
                this.tenant = tenant;
                return this.loadAndSetProvider(tenant);
              }
              if (idTokenResult?.claims['admin']) {
                this.admin = true;
                return this.loadAndSetProvider();
              } else {
                const providerDoc = doc(collection(this.firestore, 'Providers'), fireuser.uid);
                return docSnapshots(providerDoc).pipe(
                  map((p) => {
                    return p.data() as Provider;
                  }),
                );
              }
            }),
          );
        } else {
          return of(undefined);
        }
      }),
    );
  }

  public loadAndSetProvider(tenant?: string): Observable<Provider | undefined> {
    this.providers = this.getProviders(tenant);
    const provId = localStorage.getItem('provider');
    if (provId) {
      const providerDoc = doc(collection(this.firestore, 'Providers'), provId);
      return docSnapshots(providerDoc).pipe(
        map((p) => {
          return p.data() as Provider;
        }),
      );
    } else {
      this.router.navigate(['/providers']);
      return of(undefined);
    }
  }

  public getProviders(tenant?: string): Observable<any> {
    console.log(tenant);
    const providerCollection = collection(this.firestore, 'Providers');

    if (tenant) {
      const ref = query(providerCollection, where('tenant', '==', tenant));
      return collectionChanges(ref).pipe(
        map((p) => {
          return p.map((d) => {
            const data = d.doc.data();
            const id = d.doc.id;
            return { id, ...data };
          });
        }),
      );
    } else {
      return collectionChanges(providerCollection).pipe(
        map((p) => {
          return p.map((d) => {
            const data = d.doc.data();
            const id = d.doc.id;
            return { id, ...data };
          });
        }),
      );
    }
  }

  public setActiveProvider(provider: Provider): void {
    localStorage.setItem('provider', provider.uid);
    const providerDoc = doc(collection(this.firestore, 'Providers'), provider.uid);
    this.provider = docSnapshots(providerDoc).pipe(
      map((p) => {
        const data = p.data() as Provider;
        const id = p.id;
        return { id, ...data };
      }),
    );
    this.router.navigate(['/']);
  }

  public emailSignup(registerFormData: any): Promise<any> {
    console.log(registerFormData.email, registerFormData.password);
    return createUserWithEmailAndPassword(this.auth, registerFormData.email, registerFormData.password).then(
      (providerCred) => {
        return this.setProviderDoc(providerCred, registerFormData).then(() => {
          return this.setProviderPreferencesDoc(providerCred.user.uid);
        });
      },
    );
  }

  emailLogin(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password).then((provider) => {
      this.router.navigate(['/']);
    });
  }

  public signout(): Promise<void> {
    return signOut(this.auth).then(() => {
      this.router.navigate(['/login']);
    });
  }

  public updateProvider(provider: Provider | string, data: Partial<Provider>) {
    const uid = typeof provider === 'string' ? provider : provider.uid;
    const docRef = doc(collection(this.firestore, 'Providers'), uid);

    return updateDoc(docRef, data)
      .then((docRef) => {
        this.snackBar.open('Gespeichert', '', {
          duration: 3000,
        });
      })
      .catch(() => {
        this.snackBar.open('Ein Fehler ist aufgetreten', '', {
          duration: 3000,
        });
      });
  }

  private setProviderDoc(userCred: UserCredential, providerData: any) {
    const link: Link = {
      uid: userCred.user.uid,
      providerId: userCred.user.uid,
    };

    const currentlanguage = (this.translate.currentLang as SupportedLanguages) ?? SupportedLanguages.DE;

    const data: Partial<Provider> = {
      uid: userCred.user.uid,

      email: providerData.email,
      name: providerData.name,
      phone: providerData.phone,
      address: {
        [currentlanguage]: providerData.address,
      } as LanguageMap,
      town: {
        [currentlanguage]: providerData.town,
      } as LanguageMap,
      zip: providerData.zip,
      country: providerData.country,

      website: providerData.website,
      vat: providerData.vat,
      pickupAvailable: providerData.pickupAvailable,
      deliveryAvailable: providerData.deliveryAvailable,
      localAvailable: providerData.localAvailable,
      contact_name: providerData.contact_name,
      contact_phone: providerData.contact_phone,
      ingredients_initialized: false,
      type: {
        [currentlanguage]: providerData.type,
      } as LanguageMap,
      category: providerData.category,
      store_acceptOrders: false,
      language: currentlanguage,
      link: '',

      verified: false,
      menuLanguages: [SupportedLanguages.DE, SupportedLanguages.IT, SupportedLanguages.EN],
      store_visible: false, // always false initially,
      creation_date: Timestamp.now(),
    };

    const linkDoc = doc(collection(this.firestore, 'Links'), userCred.user.uid);

    const providerDoc = doc(collection(this.firestore, 'Providers'), userCred.user.uid);

    return setDoc(providerDoc, data as Provider, { merge: true }).then(() => {
      return setDoc(linkDoc, link);
    });
  }

  private setProviderPreferencesDoc(uid: string) {
    const orders: Partial<ProviderOrderPreferences> = {
      sound_enabled: true,
      last_modified: Timestamp.now(),
    };

    const providerDoc = doc(collection(this.firestore, 'Providers'), uid);
    const providerPrefDoc = doc(collection(providerDoc, 'Preferences'), 'orders');

    return setDoc(providerPrefDoc, orders, { merge: true });
  }
}
