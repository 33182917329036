import { Timestamp } from 'firebase/firestore';

export interface Driver {
  id?: string;
  colorCode: string;
  countryCode: string;
  email: string;
  enabled: boolean;
  lastSeen: Timestamp;
  registeredDate: Timestamp;
  name: string;
  phone: string;
  photoUrl: string;
  providerIds: string[];
  token: string;
}
